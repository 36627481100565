// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileUploadForm_fileUploadForm__r\\+wDw {
    display: flex;
    align-items: center; /* Aligns uploader and preview vertically centered */
    justify-content: flex-start; /* Aligns content to the left */
    padding: 0.833rem;
    background-color: #f4f4f4;
    border-radius: 0.278rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
    max-width: 100%;
  }
  
  /* Space between FileUploader and ImagePreview */
  .FileUploadForm_fileUploader__fwHiL {
    margin-right: 1rem; /* Add space between uploader and preview */
  }
  
  .FileUploadForm_imagePreview__jVgUL {
    width: 120px; /* Compact width */
    height: 120px; /* Compact height */
    border: 2px solid #a45ca3;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    border-radius: 0.278rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow to the preview box */
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .FileUploadForm_fileUploadForm__r\\+wDw {
      padding: 0.667rem;
    }
  
    .FileUploadForm_imagePreview__jVgUL {
      width: 100px;
      height: 100px;
    }
  }
  
  @media (max-width: 450px) {
    .FileUploadForm_fileUploadForm__r\\+wDw {
      padding: 0.556rem;
    }
  
    .FileUploadForm_imagePreview__jVgUL {
      width: 80px;
      height: 80px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/molecules/FileUploadForm/FileUploadForm.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB,EAAE,oDAAoD;IACzE,2BAA2B,EAAE,+BAA+B;IAC5D,iBAAiB;IACjB,yBAAyB;IACzB,uBAAuB;IACvB,wCAAwC,EAAE,iBAAiB;IAC3D,eAAe;EACjB;;EAEA,gDAAgD;EAChD;IACE,kBAAkB,EAAE,2CAA2C;EACjE;;EAEA;IACE,YAAY,EAAE,kBAAkB;IAChC,aAAa,EAAE,mBAAmB;IAClC,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,uBAAuB;IACvB,wCAAwC,EAAE,kCAAkC;EAC9E;;EAEA,0CAA0C;EAC1C;IACE;MACE,iBAAiB;IACnB;;IAEA;MACE,YAAY;MACZ,aAAa;IACf;EACF;;EAEA;IACE;MACE,iBAAiB;IACnB;;IAEA;MACE,WAAW;MACX,YAAY;IACd;EACF","sourcesContent":[".fileUploadForm {\n    display: flex;\n    align-items: center; /* Aligns uploader and preview vertically centered */\n    justify-content: flex-start; /* Aligns content to the left */\n    padding: 0.833rem;\n    background-color: #f4f4f4;\n    border-radius: 0.278rem;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */\n    max-width: 100%;\n  }\n  \n  /* Space between FileUploader and ImagePreview */\n  .fileUploader {\n    margin-right: 1rem; /* Add space between uploader and preview */\n  }\n  \n  .imagePreview {\n    width: 120px; /* Compact width */\n    height: 120px; /* Compact height */\n    border: 2px solid #a45ca3;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #f4f4f4;\n    border-radius: 0.278rem;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow to the preview box */\n  }\n  \n  /* Responsive design for smaller screens */\n  @media (max-width: 768px) {\n    .fileUploadForm {\n      padding: 0.667rem;\n    }\n  \n    .imagePreview {\n      width: 100px;\n      height: 100px;\n    }\n  }\n  \n  @media (max-width: 450px) {\n    .fileUploadForm {\n      padding: 0.556rem;\n    }\n  \n    .imagePreview {\n      width: 80px;\n      height: 80px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileUploadForm": `FileUploadForm_fileUploadForm__r+wDw`,
	"fileUploader": `FileUploadForm_fileUploader__fwHiL`,
	"imagePreview": `FileUploadForm_imagePreview__jVgUL`
};
export default ___CSS_LOADER_EXPORT___;
