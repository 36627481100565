// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* List.module.css */
.List_list__eFr3j {
    list-style-type: none; /* Убираем стандартные маркеры */
    padding-left: 20px;
    margin-top: 1rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/atoms/TextComponents/List/List.module.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,qBAAqB,EAAE,gCAAgC;IACvD,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":["/* List.module.css */\n.list {\n    list-style-type: none; /* Убираем стандартные маркеры */\n    padding-left: 20px;\n    margin-top: 1rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `List_list__eFr3j`
};
export default ___CSS_LOADER_EXPORT___;
