// Pricing.tsx
import React from 'react';
import ServiceInfoCard from '../../molecules/ServiceInfoCard/ServiceInfoCard';
import SpecialPriceCard from '../../molecules/SpecialPriceCard/SpecialPriceCard';
import { useDispatch } from 'react-redux';
import { setCurrentStep } from '../../../redux/slices/bookingSlice';
import styles from './Pricing.module.css';
import Footnote from '../../atoms/Footnote/Footnote';

const Pricing: React.FC = () => {
  const dispatch = useDispatch();

  const handleOpenForm = () => {
    dispatch(setCurrentStep(1));
  };

  return (
    <div className={styles.pricingContainer}>
      <ServiceInfoCard />
      <div className={styles.priceCardsContainer}>
        <div className={styles.cardContainer}>
          <SpecialPriceCard 
            title="SIMPLE ONLINE WEDDING"
            price="3000NIS"
            highlighted={true}   
            buttonLabel="BOOK TODAY!"
            action={handleOpenForm} 
            features={[
              { primaryText: '100% Satisfaction Guarantee', secondaryText: '' },
              { primaryText: 'Wedding Ceremony with Experienced Officiant', secondaryText: '' },
              { primaryText: 'Premium Concierge Support at Every Step', secondaryText: '' },
              { primaryText: 'Physical and Digital Marriage Certificate', secondaryText: '' },
              { primaryText: 'Expert Advice for Marriage Recognition in 150+ countries', secondaryText: '' },
              { primaryText: 'Video Recording of Your Ceremony', secondaryText: '' },
              { primaryText: 'Cloud Storage of Your Video Recording', secondaryText: '' },
              { primaryText: 'Get a Full Refund if You Change Your Mind', secondaryText: '' },
            ]}
          /> 
          <Footnote text="You Must Provide 2 Witnesses" />
        </div>
        
        <div className={styles.cardContainer}>
          <SpecialPriceCard  
            title="COMPLEX ONLINE WEDDING"
            price="3500NIS"
            highlighted={false}   
            buttonLabel="BOOK TODAY!"
            action={handleOpenForm} 
            features={[
              { primaryText: '100% SIMPLE ONLINE WEDDING +', secondaryText: '' },
              { primaryText: 'Two Witnesses', secondaryText: 'We provide your required witnesses.' },
              { primaryText: 'Apostille Stamp', secondaryText: 'Allows the marriage certificate to be recognized internationally' },
              { primaryText: 'Live Translation', secondaryText: 'Available in Spanish, Italian, French, Chinese, Portuguese, Filipino, & more' },
            ]} 
          /> 
          <Footnote text="Shipping Outside the USA Not Included" />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
