import React from 'react';
import styles from './FileUploader.module.css';

interface FileUploaderProps {
  onFileSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileName?: string; 
  disabled?: boolean;
}

const FileUploader: React.FC<FileUploaderProps> = ({ onFileSelect, fileName, disabled = false }) => {
  return (
    <div>
      <label className={styles.fileUploaderLabel}>
        <input
          type="file"
          onChange={onFileSelect}
          disabled={disabled}
          className={styles.fileUploader}
        />
        <span className={styles.fileUploaderText}>
          {fileName ? fileName : 'Choose a file'} 
        </span>
      </label>
    </div>
  );
};

export default FileUploader;
