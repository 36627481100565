import React from "react";
import { useDispatch } from "react-redux"; // Для взаимодействия с Redux
import Title from "../../atoms/TextComponents/Title/Title";
import Subtitle from "../../atoms/TextComponents/Subtitle/Subtitle";
import Paragraph from "../../atoms/TextComponents/Paragraph/Paragraph";
import List from "../../atoms/TextComponents/List/List";
import HorizontalDivider from "../../atoms/Divider/HorizontalDivider/HorizontalDivider";
import Button from "../../atoms/Button/Button"; // Импорт кнопки
import styles from "./PackageProduct.module.css";
import { setCurrentStep } from "../../../redux/slices/bookingSlice"; // Действие для открытия формы

interface PackageProductProps {
  title: string; // Название пакета
  price: string; // Цена
  items: {
    primaryText: string;
    secondaryText?: string;
    isLink?: boolean;
    linkUrl?: string;
    withHeart?: boolean; // Использовать ли сердечки
  }[]; // Список включений
  notes?: string[]; // Примечания
}

const PackageProduct: React.FC<PackageProductProps> = ({ title, price, items, notes = [] }) => {
  const dispatch = useDispatch();

  const handleBookingClick = () => {
    dispatch(setCurrentStep(1)); // Устанавливаем первый шаг формы записи
  };

  return (
    <div className={styles.packageProduct}>
      <div className={styles.header}>
        <Title text={title} />
        <Title text={price} className={styles.price} />
        <div className={styles.dividerWrapper}>
          <div className={styles.dividerContainer}>
            <HorizontalDivider width="80%" />
          </div>
        </div>
      </div>
      <div className={styles.listContainer}>
        <List items={items} withHeart={true} />
      </div>
      <div className={styles.notes}>
        {notes.map((note, index) => (
          <Paragraph key={index} text={note} small={true} />
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <Button label="Book your wedding now!" onClick={handleBookingClick} />
      </div>
    </div>
  );
};

export default PackageProduct;
