import React, { useState } from 'react';
import TestimonialCard from '../../molecules/TestimonialCard/TestimonialCard';
import styles from './TestimonialsGrid.module.css';

interface Testimonial {
  photo: string;
  name: string;
  date: string;
  review: string;
}

interface TestimonialsGridProps {
  testimonials: Testimonial[]; // Полный список всех отзывов
}

const TestimonialsGrid: React.FC<TestimonialsGridProps> = ({ testimonials }) => {
  const INITIAL_COUNT = 5; // Изначальное количество отображаемых отзывов
  const LOAD_MORE_COUNT = 5; // Количество отзывов, загружаемых при клике на "See more"

  const [visibleCount, setVisibleCount] = useState(INITIAL_COUNT);

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => Math.min(prevCount + LOAD_MORE_COUNT, testimonials.length));
  };

  const visibleTestimonials = testimonials.slice(0, visibleCount);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Reviews</h2>
      <div className={styles.grid}>
        {visibleTestimonials.map((testimonial, index) => (
          <div key={index} className={styles.cardWrapper}>
            <TestimonialCard
              photo={testimonial.photo}
              name={testimonial.name}
              date={testimonial.date}
              review={testimonial.review}
            />
          </div>
        ))}
      </div>
      {visibleCount < testimonials.length && (
        <button className={styles.seeMoreButton} onClick={handleLoadMore}>
          See more
        </button>
      )}
    </div>
  );
};

export default TestimonialsGrid;
