import React, { useState, useEffect } from 'react';
import FileUploader from '../../atoms/FileUploader/FileUploader';
import ImagePreview from '../../atoms/ImagePreview/ImagePreview';
import styles from './FileUploadForm.module.css';

interface FileUploadFormProps {
  onFileSelect: (file: File) => void;
  existingFile?: File | null; 
}

const FileUploadForm: React.FC<FileUploadFormProps> = ({ onFileSelect, existingFile }) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>(''); 

  useEffect(() => {
    if (existingFile) {
      setFileName(existingFile.name); 
      setImagePreview(URL.createObjectURL(existingFile)); 
    }
  }, [existingFile]);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file)); 
      setFileName(file.name); 
      onFileSelect(file); 
    }
  };

  return (
    <div className={styles.fileUploadForm}>
      {/* File Uploader */}
      <FileUploader onFileSelect={handleFileSelect} fileName={fileName} />

      {/* Compact Image Preview */}
      {imagePreview && (
        <div className={styles.imagePreview}>
          <ImagePreview src={imagePreview} alt="Preview" />
        </div>
      )}
    </div>
  );
};

export default FileUploadForm;
