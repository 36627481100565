import React from 'react';
import BurgerMenuIcon from '../../atoms/BurgerMenuIcon/BurgerMenuIcon';
import LanguageSwitcher from '../../atoms/LanguageSwitcher/LanguageSwitcher';
import styles from './NavBarRightPanel.module.css';

interface NavBarRightPanelProps {
  isMenuOpen: boolean;
  onBurgerClick: () => void;
  onLanguageChange: (language: string) => void;
  children?: React.ReactNode; // Allow adding other elements
}

const NavBarRightPanel: React.FC<NavBarRightPanelProps> = ({
  isMenuOpen,
  onBurgerClick,
  onLanguageChange,
  children,
}) => {
  return (
    <div className={styles.rightPanel}>
      {/* Language switcher */}
      <div className={styles.item}>
        <LanguageSwitcher onLanguageChange={onLanguageChange} />
      </div>

      {/* Burger menu icon */}
      <div className={styles.item}>
        <BurgerMenuIcon isOpen={isMenuOpen} onClick={onBurgerClick} />
      </div>

      {/* Additional elements */}
      {children && <div className={styles.additionalItems}>{children}</div>}
    </div>
  );
};

export default NavBarRightPanel;
