import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { setCurrentStep } from '../../../../redux/slices/bookingSlice';
import Button from '../../../atoms/Button/Button';
import styles from './Confirmation.module.css';
import axios from 'axios';

interface ConfirmationProps {
  person1PassportPhoto: File | null;
  person2PassportPhoto: File | null;
}

const Confirmation: React.FC<ConfirmationProps> = ({
  person1PassportPhoto,
  person2PassportPhoto,
}) => {
  const dispatch = useDispatch();
  const { date, time, person1, person2 } = useSelector((state: RootState) => state.booking);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('date', date);
    formData.append('time', time);
    formData.append('person1FirstName', person1.firstName);
    formData.append('person1LastName', person1.lastName);
    formData.append('person1DateOfBirth', person1.dateOfBirth);
    formData.append('person1PhoneNumber', person1.phoneNumber);
    if (person1PassportPhoto) {
      formData.append('person1PassportPhoto', person1PassportPhoto);
    }
    formData.append('person2FirstName', person2.firstName);
    formData.append('person2LastName', person2.lastName);
    formData.append('person2DateOfBirth', person2.dateOfBirth);
    formData.append('person2PhoneNumber', person2.phoneNumber);
    if (person2PassportPhoto) {
      formData.append('person2PassportPhoto', person2PassportPhoto);
    }

    axios
      .post('/api/submit', formData)
      .then(() => {
        alert('Form submitted!');
        dispatch(setCurrentStep(0));
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
        alert('An error occurred while submitting the form.');
      });
  };

  return (
    <div className={styles.container}>
      {/* Контейнер для даты и времени */}
        <div className={styles.dateContainer}>
          <p className={styles.dateTime}>Date: {date}</p>
          <p className={styles.dateTime}>Time: {time}</p>
        </div>

      {/* Контейнер для карточек персон */}
      <div className={styles.personsContainer}>
        <div className={styles.personDetails}>
          <p className={styles.personHeader}>Person 1</p>
          <div className={styles.personInfo}>
            <strong>Name:</strong> {person1.firstName} {person1.lastName}
          </div>
          <div className={styles.personInfo}>
            <strong>Phone Number:</strong> {person1.phoneNumber}
          </div>
          {person1PassportPhoto && (
            <div className={styles.passportPhoto}>
              <img
                src={URL.createObjectURL(person1PassportPhoto)}
                alt="Person 1 Passport"
                className={styles.passportImage}
              />
            </div>
          )}
          <Button
            label="Edit"
            onClick={() => dispatch(setCurrentStep(2))}
            className={styles.editButton}
          />
        </div>

        <div className={styles.personDetails}>
          <p className={styles.personHeader}>Person 2</p>
          <div className={styles.personInfo}>
            <strong>Name:</strong> {person2.firstName} {person2.lastName}
          </div>
          <div className={styles.personInfo}>
            <strong>Phone Number:</strong> {person2.phoneNumber}
          </div>
          {person2PassportPhoto && (
            <div className={styles.passportPhoto}>
              <img
                src={URL.createObjectURL(person2PassportPhoto)}
                alt="Person 2 Passport"
                className={styles.passportImage}
              />
            </div>
          )}
          <Button
            label="Edit"
            onClick={() => dispatch(setCurrentStep(3))}
            className={styles.editButton}
          />
        </div>
      </div>

      {/* Контейнер для кнопки Get Married */}
      <div className={styles.getMarriedContainer}>
        <Button
          label="Get Married"
          onClick={handleSubmit}
          className={styles.getMarriedButton}
        />
      </div>
    </div>
  );
};

export default Confirmation;
