import React from 'react';
import styles from './InfoCardItem.module.css';

interface InfoCardItemProps {
  primaryText: string;
  secondaryText?: string;
}

const InfoCardItem: React.FC<InfoCardItemProps> = ({ primaryText, secondaryText }) => {
  return (
    <div className={styles.container}>
      <span className={styles.primaryText}>{primaryText}</span>
      {secondaryText && <span className={styles.secondaryText}> {secondaryText}</span>}
      <hr className={styles.line} />
    </div>
  );
};

export default InfoCardItem;
