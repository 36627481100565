// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddOnsSection_addOnsSection__Wd5vG {
   margin-top: 1.2rem;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 40%;
    background-color: #a30c0c21;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    align-self: flex-start; /* Располагается сверху относительно основного блока */
    text-align: left;
  }
  
  .AddOnsSection_addOnsTitle__FC4mr {
    font-size: 1.2rem;
    font-weight: bold;
    color: #a45ca3;
  }s
  
  .AddOnsSection_list__gh5gY {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/molecules/AddOnsSection/AddOnsSection.module.css"],"names":[],"mappings":"AAAA;GACG,kBAAkB;IACjB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,2BAA2B;IAC3B,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,sBAAsB,EAAE,sDAAsD;IAC9E,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;EAChB,CAAC;;;IAGC,gBAAgB;IAChB,UAAU;IACV,SAAS;EACX","sourcesContent":[".addOnsSection {\n   margin-top: 1.2rem;\n    min-width: 300px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    flex: 1 1 40%;\n    background-color: #a30c0c21;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);\n    align-self: flex-start; /* Располагается сверху относительно основного блока */\n    text-align: left;\n  }\n  \n  .addOnsTitle {\n    font-size: 1.2rem;\n    font-weight: bold;\n    color: #a45ca3;\n  }s\n  \n  .list {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addOnsSection": `AddOnsSection_addOnsSection__Wd5vG`,
	"addOnsTitle": `AddOnsSection_addOnsTitle__FC4mr`,
	"list": `AddOnsSection_list__gh5gY`
};
export default ___CSS_LOADER_EXPORT___;
