import React from 'react';
import ListItem from '../ListItem/ListItem';
import styles from './List.module.css';

interface ListProps {
  withHeart?: boolean; 
  items: {
    primaryText: string;
    secondaryText?: string;
    isLink?: boolean;
    linkUrl?: string;
  }[];
}

const List: React.FC<ListProps> = ({ items,  withHeart = false }) => {
  return (
    <ul className={styles.list}>
      {items.map((item, index) => (
        <ListItem
          key={index}
          primaryText={item.primaryText}
          secondaryText={item.secondaryText}
          isLink={item.isLink}
          linkUrl={item.linkUrl}
          withHeart={withHeart} 
        />
      ))}
    </ul>
  );
};

export default List;
