import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Popup from '../../molecules/Popup/Popup';
import TextInput from '../../atoms/Input/TextInput';
import SubmitButton from '../../atoms/SubmitButton/SubmitButton';
import axios from 'axios';
import styles from './ConsultationForm.module.css';

interface ConsultationFormProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ConsultationFormValues {
  name: string;
  phone?: string;
  email?: string;
}

const ConsultationForm: React.FC<ConsultationFormProps> = ({ isOpen, onClose }) => {
  const initialValues: ConsultationFormValues = {
    name: '',
    phone: '',
    email: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string()
      .matches(/^[0-9]{10,15}$/, 'Invalid phone number format')
      .nullable(),
    email: Yup.string()
      .email('Invalid email format')
      .nullable(),
  }).test(
    'phone-or-email',
    'Either phone or email is required',
    (values) => !!(values.phone || values.email)
  );

  const handleSubmit = (values: ConsultationFormValues, { resetForm }: any) => {
    const formData = new FormData();
    formData.append('name', values.name);
    if (values.phone) formData.append('phone', values.phone);
    if (values.email) formData.append('email', values.email);

    axios
      .post('/api/consultation-request', formData)
      .then(() => {
        alert('Consultation request submitted successfully!');
        resetForm();
        onClose();
      })
      .catch((error) => {
        console.error('Error submitting consultation form:', error);
        alert('An error occurred while submitting the form.');
      });
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title="Consultation Request">
      <div className={styles.container}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className={styles.form}>
            <div className={styles.field}>
              <label>Name</label>
              <Field
                name="name"
                as={TextInput}
                placeholder="Enter your name"
              />
              <ErrorMessage name="name" component="div" className={styles.error} />
            </div>

            <div className={styles.field}>
              <label>Phone</label>
              <Field
                name="phone"
                as={TextInput}
                placeholder="Enter your phone number"
              />
              <ErrorMessage name="phone" component="div" className={styles.error} />
            </div>

            <div className={styles.field}>
              <label>Email</label>
              <Field
                name="email"
                as={TextInput}
                placeholder="Enter your email"
              />
              <ErrorMessage name="email" component="div" className={styles.error} />
            </div>

            <div className={styles.buttons}>
              <SubmitButton label="Submit" />
            </div>
          </Form>
        </Formik>
      </div>
    </Popup>
  );
};

export default ConsultationForm;
