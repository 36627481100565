import React from 'react';
import Logo from '../../atoms/Logo/Logo';
import { ReactComponent as LogoIcon } from '../../../assets/images/LOGO.svg'

import styles from './AnimatedText.module.css';

const AnimatedText: React.FC = () => {
  return (
    <div className={styles.container}>
      {/* First line: "Love is legal" */}
      <h1 className={styles.loveIsLegal}>Love is legal</h1>
      
      {/* Second line: "WllUMarryMy?" with delayed "Online" */}
      <h2 className={styles.secondLine}>
        WllUMarryMy?
        <span className={styles.onlineText}>-Online-</span>
      </h2>
    </div>
  );
};

export default AnimatedText;
