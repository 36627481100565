import React from 'react';
import styles from './BurgerMenuIcon.module.css';

interface BurgerMenuIconProps {
  isOpen: boolean; // Indicates if the menu is open
  onClick: () => void; // Callback for when the menu is clicked
}

const BurgerMenuIcon: React.FC<BurgerMenuIconProps> = ({ isOpen, onClick }) => {
  return (
    <div className={`${styles.burgerMenu} ${isOpen ? styles.open : ''}`} onClick={onClick}>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
    </div>
  );
};

export default BurgerMenuIcon;
