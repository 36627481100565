import React from "react";
import ContentWrapper from "../components/molecules/ContentWrapper/ContentWrapper";
import HeaderWithBackground from "../components/molecules/HeaderWithBackground/HeaderWithBackground";
import InfoAboutUs from "../components/molecules/InfoAboutUs/InfoAboutUs";
import aboutUsBackground from '../assets/images/married_back.jpg';

export function AboutUsPage() {
    return (
        <div className="aboutUsContainer">
            <HeaderWithBackground 
                backgroundImage={aboutUsBackground} 
                title="About Us – Meet Our Team" 
            />
            <ContentWrapper>
                <InfoAboutUs />
            </ContentWrapper>
        </div>
    );
}
