import React from 'react';
import styles from './SpecialCardBackground.module.css';

interface SpecialCardBackgroundProps {
  children: React.ReactNode;
  highlighted?: boolean;
}

const SpecialCardBackground: React.FC<SpecialCardBackgroundProps> = ({ children, highlighted = false }) => {
  return (
    <div className={`${styles.cardBackground} ${highlighted ? styles.highlighted : styles.simple}`}>
      {children}
    </div>
  );
};

export default SpecialCardBackground;
