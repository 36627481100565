import React from 'react';
import { Link } from 'react-router-dom'; // Используем Link из react-router
import styles from './ServiceInfoCard.module.css';

const ServiceInfoCard: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Don't pay extra for the same services!<Link to="/pricing" className={styles.seeMore}>
        See more
      </Link></h1>
      
      <p className={styles.description}>
        Compare and save! Save hundreds of dollars with our reasonable prices. 
        Don’t pay double or triple or more for an online wedding, when you can get excellent service for less!
      </p>
      <p className={styles.subheading}>We don’t charge extra for:</p>
      <ul className={styles.list}>
        <li>“Rush” weddings or elopements</li>
        <li>Rescheduling your ceremony</li>
        <li>Including wedding guests</li>
        <li>Information or Advice</li>
        <li>A pre-booking consultation</li>
      </ul>
    </div>
  );
};

export default ServiceInfoCard;
