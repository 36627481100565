// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footnote_footnote__9vkcU {
    font-size: 0.75rem; /* Маленький шрифт для сносок */
    color: #666; /* Второстепенный цвет текста */
    line-height: 1.4;
    margin-top: 0.5rem;
    margin-bottom: 0;
    text-align: left;
    font-style: italic; /* Курсив для стилизации сноски */
  }
  
  /* Адаптивный размер шрифта для еще лучшей читабельности на мобильных */
  @media (max-width: 450px) {
    .Footnote_footnote__9vkcU {
      font-size: 0.7rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/atoms/Footnote/Footnote.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB,EAAE,+BAA+B;IACnD,WAAW,EAAE,+BAA+B;IAC5C,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB,EAAE,iCAAiC;EACvD;;EAEA,uEAAuE;EACvE;IACE;MACE,iBAAiB;IACnB;EACF","sourcesContent":[".footnote {\n    font-size: 0.75rem; /* Маленький шрифт для сносок */\n    color: #666; /* Второстепенный цвет текста */\n    line-height: 1.4;\n    margin-top: 0.5rem;\n    margin-bottom: 0;\n    text-align: left;\n    font-style: italic; /* Курсив для стилизации сноски */\n  }\n  \n  /* Адаптивный размер шрифта для еще лучшей читабельности на мобильных */\n  @media (max-width: 450px) {\n    .footnote {\n      font-size: 0.7rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footnote": `Footnote_footnote__9vkcU`
};
export default ___CSS_LOADER_EXPORT___;
