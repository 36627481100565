// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* InfoMarried.module.css */

.InfoMarried_infoMarried__HHPw7 {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .InfoMarried_introSection__2KPUP {
    margin-bottom: 2rem;
  }
  
  .InfoMarried_introSection__2KPUP p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  .InfoMarried_introSection__2KPUP p:first-child {
    font-weight: bold;
    color: #333; /* Темный цвет для акцентов */
  }
  
  .InfoMarried_introSection__2KPUP a {
    color: #6a1b9a; /* Ссылки фиолетового цвета */
    text-decoration: underline;
  }
  
  .InfoMarried_introSection__2KPUP a:hover {
    color: #a45ca3;
  }
  
  .InfoMarried_buttonWrapper__nVF3e {
    text-align: center;
    margin: 2rem 0; /* Отступ для кнопки сверху и снизу */
  }`, "",{"version":3,"sources":["webpack://./src/components/molecules/InfoMarried/InfoMarried.module.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,WAAW;IACX,gCAAgC;EAClC;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,WAAW,EAAE,6BAA6B;EAC5C;;EAEA;IACE,cAAc,EAAE,6BAA6B;IAC7C,0BAA0B;EAC5B;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,cAAc,EAAE,qCAAqC;EACvD","sourcesContent":["/* InfoMarried.module.css */\n\n.infoMarried {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    color: #333;\n    font-family: 'Arial', sans-serif;\n  }\n  \n  .introSection {\n    margin-bottom: 2rem;\n  }\n  \n  .introSection p {\n    margin-bottom: 1rem;\n    line-height: 1.6;\n  }\n  \n  .introSection p:first-child {\n    font-weight: bold;\n    color: #333; /* Темный цвет для акцентов */\n  }\n  \n  .introSection a {\n    color: #6a1b9a; /* Ссылки фиолетового цвета */\n    text-decoration: underline;\n  }\n  \n  .introSection a:hover {\n    color: #a45ca3;\n  }\n  \n  .buttonWrapper {\n    text-align: center;\n    margin: 2rem 0; /* Отступ для кнопки сверху и снизу */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoMarried": `InfoMarried_infoMarried__HHPw7`,
	"introSection": `InfoMarried_introSection__2KPUP`,
	"buttonWrapper": `InfoMarried_buttonWrapper__nVF3e`
};
export default ___CSS_LOADER_EXPORT___;
