import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../atoms/Button/Button';
import AnimatedText from './AnimatedText';
import { setCurrentStep } from '../../../redux/slices/bookingSlice';
import ConsultationForm from '../ConsultationForm/ConsultationForm';
// import { RootState } from '../../../redux/store';
import styles from './HeroSection.module.css';

const HeroSection: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isConsultationFormOpen, setIsConsultationFormOpen] = useState(false);
  const dispatch = useDispatch();
  // const currentStep = useSelector((state: RootState) => state.booking.currentStep);

  const handleOpenForm = () => {
    dispatch(setCurrentStep(1));
  };
  const handleOpenConsultationForm = () => {
    setIsConsultationFormOpen(true); // Открываем форму консультации
  };
  const handleCloseConsultationForm = () => {
    setIsConsultationFormOpen(false); // Закрываем форму консультации
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <section className={styles.heroSection}>
      <div className={styles.overlay}>
        <div className={`${styles.content} `}>
        <AnimatedText />
          <div className={styles.contentContainerBottom}>
            <div className={`${styles.bookSection} ${isLoaded ? styles.loaded : ''}`}>
              <h1 className={styles.title}>Wedding Online</h1>
              <p className={styles.subtitle}> 
                Say  "I do" from the comfort of your home.
              </p>
              <p className={styles.subtitle}>
                Celebrate your love from anywhere in the world with our online wedding services! 
              </p>
              <div className={styles.buttonGroup}>
                <Button label="BOOK YOUR ONLINE WEDDING NOW!" onClick={handleOpenForm} type="primary" />
                <Button label="FREE 15-MINUTE CONSULTATION" onClick={handleOpenConsultationForm} type="secondary" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConsultationForm isOpen={isConsultationFormOpen} onClose={handleCloseConsultationForm} />
    </section>
  );
};

export default HeroSection;
