import React from 'react';
import styles from './ListItem.module.css';

interface ListItemProps {
  primaryText: string;
  secondaryText?: string;
  isLink?: boolean;
  linkUrl?: string;
  withHeart?: boolean; // Указывает, использовать ли сердечко
}

const ListItem: React.FC<ListItemProps> = ({ primaryText, secondaryText, isLink, linkUrl, withHeart = false }) => {
  return (
    <li className={`${styles.listItem} ${withHeart ? styles.heartItem : styles.dashItem}`}>
      {withHeart ? (
        <span className={styles.iconHeart} />
      ) : (
        <span className={styles.iconDash}></span>
      )}
      {isLink ? (
        <a href={linkUrl} target="_blank" rel="noopener noreferrer" className={styles.link}>
          <span className={styles.inlineText}>
            <span className={styles.boldText}>{primaryText}</span>
            {secondaryText && <span className={styles.secondaryText}>{` ${secondaryText}`}</span>}
          </span>
        </a>
      ) : (
        <span className={styles.inlineText}>
          <span className={styles.boldText}>{primaryText}</span>
          {secondaryText && <span className={styles.secondaryText}>{` ${secondaryText}`}</span>}
        </span>
      )}
    </li>
  );
};

export default ListItem;
