// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pricing_pricingContainer__oGIQV {
  display: flex;
  gap: 0.5rem;
  padding: 1.7rem;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #eeeaea;
  flex-wrap: wrap;
}

/* Контейнер для карточек цены */
.Pricing_priceCardsContainer__0Buzl {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  flex-grow: 1; 

}
.Pricing_cardContainer__zrb08 {
display: flex;
flex-direction: column;
justify-content: space-between;
}
/* Адаптивные стили */
@media (max-width: 1024px) {
  .Pricing_pricingContainer__oGIQV {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

}

@media (max-width: 768px) {
  .Pricing_priceCardsContainer__0Buzl {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;

  }
}

@media (max-width: 450px) {
.Pricing_pricingContainer__oGIQV{
  width: 100%;
  padding: 0rem;
}
}`, "",{"version":3,"sources":["webpack://./src/components/organism/Pricing/Pricing.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,eAAe;EACf,8BAA8B;EAC9B,uBAAuB;EACvB,yBAAyB;EACzB,eAAe;AACjB;;AAEA,gCAAgC;AAChC;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,WAAW;EACX,YAAY;;AAEd;AACA;AACA,aAAa;AACb,sBAAsB;AACtB,8BAA8B;AAC9B;AACA,qBAAqB;AACrB;EACE;IACE,WAAW;IACX,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;EACf;;AAEF;;AAEA;EACE;IACE,WAAW;IACX,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;;EAEjB;AACF;;AAEA;AACA;EACE,WAAW;EACX,aAAa;AACf;AACA","sourcesContent":[".pricingContainer {\n  display: flex;\n  gap: 0.5rem;\n  padding: 1.7rem;\n  justify-content: space-between;\n  align-items: flex-start;\n  background-color: #eeeaea;\n  flex-wrap: wrap;\n}\n\n/* Контейнер для карточек цены */\n.priceCardsContainer {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  gap: 0.5rem;\n  flex-grow: 1; \n\n}\n.cardContainer {\ndisplay: flex;\nflex-direction: column;\njustify-content: space-between;\n}\n/* Адаптивные стили */\n@media (max-width: 1024px) {\n  .pricingContainer {\n    width: 100%;\n    flex-direction: column;\n    align-items: center;\n    padding: 1rem;\n  }\n\n}\n\n@media (max-width: 768px) {\n  .priceCardsContainer {\n    width: 100%;\n    flex-direction: column;\n    align-items: center;\n    padding: 0.5rem;\n\n  }\n}\n\n@media (max-width: 450px) {\n.pricingContainer{\n  width: 100%;\n  padding: 0rem;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pricingContainer": `Pricing_pricingContainer__oGIQV`,
	"priceCardsContainer": `Pricing_priceCardsContainer__0Buzl`,
	"cardContainer": `Pricing_cardContainer__zrb08`
};
export default ___CSS_LOADER_EXPORT___;
