// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CloseButton.module.css */

.CloseButton_closeButton__HhVbK {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
  position: absolute;
  top: 5px; /* расстояние от верха, можно переопределить */
  right: 8px; /* расстояние от правого края, можно переопределить */
}

.CloseButton_closeButton__HhVbK:hover {
  transform: scale(1.05); /* Уменьшенный эффект увеличения */
}


.CloseButton_closeButton__HhVbK:focus {
  outline: none;
}

.CloseButton_cross__CoQsn {
  font-size: 2rem;
  color: #d8a7d4;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  transition: text-shadow 0.2s ease, transform 0.2s ease;
}

.CloseButton_closeButton__HhVbK:hover .CloseButton_cross__CoQsn {
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

/* Адаптивные стили */

@media (max-width: 768px) {
  .CloseButton_closeButton__HhVbK {
    top: 5px;
    right: 7px;
    
  }

  .CloseButton_cross__CoQsn {
    font-size: 1.5rem;
  }
}

@media (max-width: 450px) {
  .CloseButton_closeButton__HhVbK {
    top: 5px;
    right: 7px;
  }

  .CloseButton_cross__CoQsn {
    font-size: 1.2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/CloseButton/CloseButton.module.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,+BAA+B;EAC/B,kBAAkB;EAClB,QAAQ,EAAE,8CAA8C;EACxD,UAAU,EAAE,qDAAqD;AACnE;;AAEA;EACE,sBAAsB,EAAE,kCAAkC;AAC5D;;;AAGA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,cAAc;EACd,2CAA2C;EAC3C,sDAAsD;AACxD;;AAEA;EACE,2CAA2C;EAC3C,sBAAsB;AACxB;;AAEA,qBAAqB;;AAErB;EACE;IACE,QAAQ;IACR,UAAU;;EAEZ;;EAEA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,QAAQ;IACR,UAAU;EACZ;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["/* CloseButton.module.css */\n\n.closeButton {\n  background: none;\n  border: none;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transition: transform 0.2s ease;\n  position: absolute;\n  top: 5px; /* расстояние от верха, можно переопределить */\n  right: 8px; /* расстояние от правого края, можно переопределить */\n}\n\n.closeButton:hover {\n  transform: scale(1.05); /* Уменьшенный эффект увеличения */\n}\n\n\n.closeButton:focus {\n  outline: none;\n}\n\n.cross {\n  font-size: 2rem;\n  color: #d8a7d4;\n  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);\n  transition: text-shadow 0.2s ease, transform 0.2s ease;\n}\n\n.closeButton:hover .cross {\n  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);\n  transform: scale(1.05);\n}\n\n/* Адаптивные стили */\n\n@media (max-width: 768px) {\n  .closeButton {\n    top: 5px;\n    right: 7px;\n    \n  }\n\n  .cross {\n    font-size: 1.5rem;\n  }\n}\n\n@media (max-width: 450px) {\n  .closeButton {\n    top: 5px;\n    right: 7px;\n  }\n\n  .cross {\n    font-size: 1.2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"closeButton": `CloseButton_closeButton__HhVbK`,
	"cross": `CloseButton_cross__CoQsn`
};
export default ___CSS_LOADER_EXPORT___;
