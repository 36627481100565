// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImagePreview_imageWrapper__mq7Ko {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0.278rem;
  background-color: #f4f4f4;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.ImagePreview_imageWrapper__mq7Ko:hover {
  border-color: #92488f;
}

.ImagePreview_imageWrapper__mq7Ko:focus-within {
  box-shadow: 0 0 5px rgba(146, 72, 143, 0.5);
}

.ImagePreview_image__RlXMR {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.ImagePreview_placeholder__1rLjP {
  font-size: 0.889rem;
  color: #a45ca3;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/ImagePreview/ImagePreview.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,yBAAyB;EACzB,wDAAwD;AAC1D;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".imageWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  border-radius: 0.278rem;\n  background-color: #f4f4f4;\n  transition: border-color 0.3s ease, box-shadow 0.3s ease;\n}\n\n.imageWrapper:hover {\n  border-color: #92488f;\n}\n\n.imageWrapper:focus-within {\n  box-shadow: 0 0 5px rgba(146, 72, 143, 0.5);\n}\n\n.image {\n  max-width: 100%;\n  max-height: 100%;\n  object-fit: cover;\n}\n\n.placeholder {\n  font-size: 0.889rem;\n  color: #a45ca3;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageWrapper": `ImagePreview_imageWrapper__mq7Ko`,
	"image": `ImagePreview_image__RlXMR`,
	"placeholder": `ImagePreview_placeholder__1rLjP`
};
export default ___CSS_LOADER_EXPORT___;
