// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertChoiceBox_overlay__ctcV9 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .AlertChoiceBox_alertBox__6LCgw {
    background-color: white;
    padding: 20px;
    width: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .AlertChoiceBox_question__36rNx {
    font-size: 1.1rem;
    margin-bottom: 15px;
  }
  
  .AlertChoiceBox_buttons__zLPPz {
    display: flex;
    justify-content: space-around;
  }
  
  .AlertChoiceBox_confirmButton__cDAcX {
    background-color: #a45ca3;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .AlertChoiceBox_confirmButton__cDAcX:hover {
    background-color: #92488f;
  }
  
  .AlertChoiceBox_cancelButton__5ODQa {
    background-color: #ccc;
    color: #333;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .AlertChoiceBox_cancelButton__5ODQa:hover {
    background-color: #999;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/molecules/AlertChoiceBox/AlertChoiceBox.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;IACxC,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":[".overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n  .alertBox {\n    background-color: white;\n    padding: 20px;\n    width: 300px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    text-align: center;\n  }\n  \n  .question {\n    font-size: 1.1rem;\n    margin-bottom: 15px;\n  }\n  \n  .buttons {\n    display: flex;\n    justify-content: space-around;\n  }\n  \n  .confirmButton {\n    background-color: #a45ca3;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .confirmButton:hover {\n    background-color: #92488f;\n  }\n  \n  .cancelButton {\n    background-color: #ccc;\n    color: #333;\n    border: none;\n    padding: 10px 20px;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .cancelButton:hover {\n    background-color: #999;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `AlertChoiceBox_overlay__ctcV9`,
	"alertBox": `AlertChoiceBox_alertBox__6LCgw`,
	"question": `AlertChoiceBox_question__36rNx`,
	"buttons": `AlertChoiceBox_buttons__zLPPz`,
	"confirmButton": `AlertChoiceBox_confirmButton__cDAcX`,
	"cancelButton": `AlertChoiceBox_cancelButton__5ODQa`
};
export default ___CSS_LOADER_EXPORT___;
