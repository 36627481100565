// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateInput_input__-ztP8 {
    background-color: #f4f4f4;
    color: #333;
    border: 2px solid #a45ca3;
    padding: 0.833rem 1.25rem;
    border-radius: 0.278rem;
    font-size: 0.889rem;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .DateInput_input__-ztP8:focus {
    background-color: #ffffff;
    border-color: #92488f;
    box-shadow: 0 0 5px rgba(146, 72, 143, 0.5);
    outline: none;
  }
  
  .DateInput_input__-ztP8:disabled {
    background-color: #e0e0e0;
    border-color: #ccc;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/atoms/Input/DateInput.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,yBAAyB;IACzB,yBAAyB;IACzB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,sBAAsB;IACtB,oFAAoF;EACtF;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;IACrB,2CAA2C;IAC3C,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;EACpB","sourcesContent":[".input {\n    background-color: #f4f4f4;\n    color: #333;\n    border: 2px solid #a45ca3;\n    padding: 0.833rem 1.25rem;\n    border-radius: 0.278rem;\n    font-size: 0.889rem;\n    width: 100%;\n    box-sizing: border-box;\n    transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;\n  }\n  \n  .input:focus {\n    background-color: #ffffff;\n    border-color: #92488f;\n    box-shadow: 0 0 5px rgba(146, 72, 143, 0.5);\n    outline: none;\n  }\n  \n  .input:disabled {\n    background-color: #e0e0e0;\n    border-color: #ccc;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `DateInput_input__-ztP8`
};
export default ___CSS_LOADER_EXPORT___;
