// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_container__dVyML {
    /* max-width: 1200px; */
    margin: 0 auto;
    /* background-color: rgb(207, 233, 224); */
    /* padding: 20px; */
  }
/* .wrapper {
  width: 100%;
  max-width: 1200px;
} */`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,cAAc;IACd,0CAA0C;IAC1C,mBAAmB;EACrB;AACF;;;GAGG","sourcesContent":[".container {\n    /* max-width: 1200px; */\n    margin: 0 auto;\n    /* background-color: rgb(207, 233, 224); */\n    /* padding: 20px; */\n  }\n/* .wrapper {\n  width: 100%;\n  max-width: 1200px;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Layout_container__dVyML`
};
export default ___CSS_LOADER_EXPORT___;
