// Title.tsx
import React from 'react';
import styles from './Title.module.css';

interface TitleProps {
  text: string;
  className?: string;
}

const Title: React.FC<TitleProps> = ({ text }) => {
  return <h2 className={styles.title}>{text}</h2>;
};

export default Title;
