import React from 'react';
import styles from './Button.module.css';

interface ButtonProps {
  label: string;
  onClick?: () => void;
  type?: 'primary' | 'secondary'; // Button type for different styles
  disabled?: boolean; // Disabled state
  className?: string;
}

const Button: React.FC<ButtonProps> = ({ label, onClick, type = 'primary', disabled = false }) => {
  return (
    <button
      className={`${styles.button} ${styles[type]}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
