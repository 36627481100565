// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileUploader_fileUploaderLabel__tNyi0 {
  display: inline-block;
  background-color: #f4f4f4;
  color: #333;
  border: 2px solid #a45ca3;
  padding: 0.833rem 1.25rem;
  border-radius: 0.278rem;
  font-size: 0.889rem;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  margin-right: 1rem; /* Space between uploader and preview */
}

.FileUploader_fileUploaderLabel__tNyi0:hover {
  background-color: #ffffff;
}

.FileUploader_fileUploaderLabel__tNyi0:focus-within {
  border-color: #92488f;
  box-shadow: 0 0 5px rgba(146, 72, 143, 0.5);
}

.FileUploader_fileUploaderLabel__tNyi0:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/FileUploader/FileUploader.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,WAAW;EACX,yBAAyB;EACzB,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,8DAA8D;EAC9D,kBAAkB,EAAE,uCAAuC;AAC7D;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,2CAA2C;AAC7C;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".fileUploaderLabel {\n  display: inline-block;\n  background-color: #f4f4f4;\n  color: #333;\n  border: 2px solid #a45ca3;\n  padding: 0.833rem 1.25rem;\n  border-radius: 0.278rem;\n  font-size: 0.889rem;\n  cursor: pointer;\n  transition: background-color 0.3s ease, border-color 0.3s ease;\n  margin-right: 1rem; /* Space between uploader and preview */\n}\n\n.fileUploaderLabel:hover {\n  background-color: #ffffff;\n}\n\n.fileUploaderLabel:focus-within {\n  border-color: #92488f;\n  box-shadow: 0 0 5px rgba(146, 72, 143, 0.5);\n}\n\n.fileUploaderLabel:disabled {\n  background-color: #e0e0e0;\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileUploaderLabel": `FileUploader_fileUploaderLabel__tNyi0`
};
export default ___CSS_LOADER_EXPORT___;
