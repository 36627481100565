// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddOnItem_addOnItem__zjlrv {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  .AddOnItem_primaryText__KyfgB {
    font-weight: bold;
    color: #6a1b9a;
  }
  
  .AddOnItem_secondaryText__tvruB {
    color: #333;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/molecules/AddOnItem/AddOnItem.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,WAAW;EACb","sourcesContent":[".addOnItem {\n    margin-bottom: 1rem;\n    line-height: 1.6;\n  }\n  \n  .primaryText {\n    font-weight: bold;\n    color: #6a1b9a;\n  }\n  \n  .secondaryText {\n    color: #333;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addOnItem": `AddOnItem_addOnItem__zjlrv`,
	"primaryText": `AddOnItem_primaryText__KyfgB`,
	"secondaryText": `AddOnItem_secondaryText__tvruB`
};
export default ___CSS_LOADER_EXPORT___;
