import React from 'react';
import styles from './Paragraph.module.css';

interface ParagraphProps {
  text: string;
  isLink?: boolean;
  linkUrl?: string;
  small?: boolean; // Новое свойство для уменьшения текста
}

const Paragraph: React.FC<ParagraphProps> = ({ text, isLink, linkUrl, small }) => {
  return isLink ? (
    <a
      href={linkUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={`${styles.link} ${small ? styles.small : ''}`}
    >
      {text}
    </a>
  ) : (
    <p className={`${styles.paragraph} ${small ? styles.small : ''}`}>{text}</p>
  );
};

export default Paragraph;
