// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo_logoWrapper__gqH-h {
  /* Center the logo and allow responsive behavior */
  display: flex;
  justify-content: center;
  align-items: center;
}

.Logo_logoIcon__1njb5 {
  /* Default color styles for the logo */
  fill: #a45ca3; /* Main color of the logo */
  transition: fill 0.3s ease; /* Smooth color transition */
  max-width: 100%; /* Ensure the logo is responsive */
  height: auto;
}

/* Custom class for Logo inside NavBar to add top and bottom padding */
.Logo_navBarLogo__T0Xv2 {
  padding-top: 10px; /* Add padding above the logo */
  padding-bottom: 10px; /* Add padding below the logo */
}

/* Adjust size for smaller screens */
@media (max-width: 768px) {
  .Logo_logoIcon__1njb5 {
    width: 80px; /* Smaller logo for tablet-sized screens */
  }
}

@media (max-width: 450px) {
  .Logo_logoIcon__1njb5 {
    width: 60px; /* Even smaller for mobile screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/Logo/Logo.module.css"],"names":[],"mappings":"AAAA;EACE,kDAAkD;EAClD,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,sCAAsC;EACtC,aAAa,EAAE,2BAA2B;EAC1C,0BAA0B,EAAE,4BAA4B;EACxD,eAAe,EAAE,kCAAkC;EACnD,YAAY;AACd;;AAEA,sEAAsE;AACtE;EACE,iBAAiB,EAAE,+BAA+B;EAClD,oBAAoB,EAAE,+BAA+B;AACvD;;AAEA,oCAAoC;AACpC;EACE;IACE,WAAW,EAAE,0CAA0C;EACzD;AACF;;AAEA;EACE;IACE,WAAW,EAAE,oCAAoC;EACnD;AACF","sourcesContent":[".logoWrapper {\n  /* Center the logo and allow responsive behavior */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.logoIcon {\n  /* Default color styles for the logo */\n  fill: #a45ca3; /* Main color of the logo */\n  transition: fill 0.3s ease; /* Smooth color transition */\n  max-width: 100%; /* Ensure the logo is responsive */\n  height: auto;\n}\n\n/* Custom class for Logo inside NavBar to add top and bottom padding */\n.navBarLogo {\n  padding-top: 10px; /* Add padding above the logo */\n  padding-bottom: 10px; /* Add padding below the logo */\n}\n\n/* Adjust size for smaller screens */\n@media (max-width: 768px) {\n  .logoIcon {\n    width: 80px; /* Smaller logo for tablet-sized screens */\n  }\n}\n\n@media (max-width: 450px) {\n  .logoIcon {\n    width: 60px; /* Even smaller for mobile screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoWrapper": `Logo_logoWrapper__gqH-h`,
	"logoIcon": `Logo_logoIcon__1njb5`,
	"navBarLogo": `Logo_navBarLogo__T0Xv2`
};
export default ___CSS_LOADER_EXPORT___;
