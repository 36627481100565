import React from 'react';
import SpecialCardBackground from '../../atoms/SpecialCardBackground/SpecialCardBackground';
import Icon from '../../atoms/Icon/Icon';
import { ReactComponent as HeartIcon } from '../../../assets/images/heart-svgrepo-com.svg';
import Button from '../../atoms/Button/Button';
import HorizontalDivider from '../../atoms/Divider/HorizontalDivider/HorizontalDivider';
import InfoCardItem from '../../atoms/InfoCardItem/InfoCardItem';
import styles from './SpecialPriceCard.module.css';

interface SpecialPriceCardProps {
  highlighted?: boolean;
  action?: () => void;
  title: string;
  price: string;
  features: { primaryText: string; secondaryText?: string }[];
  buttonLabel: string;
}

const SpecialPriceCard: React.FC<SpecialPriceCardProps> = ({
  highlighted = false,
  action = () => { console.log('Button clicked'); },
  title,
  price,
  features,
  buttonLabel,
}) => {
  return (
    <SpecialCardBackground highlighted={highlighted} >
      <div className={styles.contentContainer}>
        <div className={styles.headerContainer}>
          <p className={styles.title}>{title}</p>
          <div className={styles.iconContainer}>
            <Icon IconComponent={HeartIcon} color="#a45ca3" size="40px" />
          </div>
          <p className={styles.price}>{price}</p>
          <HorizontalDivider width="80%" />
        </div>
        <div className={styles.featuresContainer}>
          {features.map((feature, index) => (
            <InfoCardItem 
              key={index}
              primaryText={feature.primaryText}
              secondaryText={feature.secondaryText}
            />
          ))}
        </div>
      </div>

      <Button label={buttonLabel} onClick={action} className={styles.bookButton} />
    </SpecialCardBackground>
  );
};

export default SpecialPriceCard;
