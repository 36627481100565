import React from 'react';
import Title from '../../atoms/TextComponents/Title/Title';
import Subtitle from '../../atoms/TextComponents/Subtitle/Subtitle';
import Paragraph from '../../atoms/TextComponents/Paragraph/Paragraph';
import List from '../../atoms/TextComponents/List/List';
import Button from '../../atoms/Button/Button';
import { useDispatch } from 'react-redux';
import { setCurrentStep } from '../../../redux/slices/bookingSlice';
import styles from './InfoMarried.module.css';

const InfoMarried = () => {
   
    const dispatch = useDispatch();
    const benefitsList = [
        {
            primaryText: "Ease of Accessibility",
            secondaryText: "Get married online from anywhere in the world, eliminating the need for travel.",
        },
        {
            primaryText: "Legal Recognition",
            secondaryText: "Our service ensures your marriage is legally recognized, regardless of borders.",
        },
        {
            primaryText: "Inclusive for Everyone",
            secondaryText: "We welcome all couples, no matter their background or location.",
        },
        {
            primaryText: "Simple Process",
            secondaryText: "Our streamlined system guides you step-by-step to ensure everything is taken care of.",
        },
        {
            primaryText: "Professional Support",
            secondaryText: "Our team is here to help you with every detail, from documentation to the ceremony.",
        },
        {
            primaryText: "Affordable Options",
            secondaryText: "We provide budget-friendly packages without compromising on quality.",
        },
        
    ];
    
    
    const handleOpenForm = () => {
        dispatch(setCurrentStep(1));
      };

    return (
        <section className={styles.infoMarried}>
            <div className={styles.introSection}>
                <Paragraph text="Marriage is a celebration of love and unity. At our service, we believe every couple deserves the chance to formalize their love, regardless of boundaries." />
                <Paragraph text="Our mission is to make marriage accessible to everyone, ensuring a smooth, stress-free process that honors your unique journey as a couple." />
            </div>
            <div className={styles.buttonWrapper}>
                <Button
                    label="BOOK YOUR ONLINE WEDDING NOW!"
                    onClick={handleOpenForm}
                    type="primary"
                />
            </div>
            <Title text="Why Choose Us for Your Marriage?" />
            <Subtitle text="Discover the benefits of getting married with our service:" />
            <List items={benefitsList} />
        </section>
    );
};

export default InfoMarried;
