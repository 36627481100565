// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TestimonialCard_card__OwiLN {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: box-shadow 0.3s ease;
  }
  
  .TestimonialCard_card__OwiLN:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .TestimonialCard_photo__Pu2iS {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .TestimonialCard_name__9ZW01 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #6a1b9a;
  }
  
  .TestimonialCard_date__j-O6H {
    font-size: 0.9rem;
    color: #888;
    margin-bottom: 10px;
  }
  
  .TestimonialCard_review__MGsyM {
    font-size: 1rem;
    color: #333;
    text-align: center;
    line-height: 1.5;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/molecules/TestimonialCard/TestimonialCard.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;IACxC,kBAAkB;IAClB,gCAAgC;EAClC;;EAEA;IACE,yCAAyC;EAC3C;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":[".card {\n    background: #fff;\n    padding: 20px;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    transition: box-shadow 0.3s ease;\n  }\n  \n  .card:hover {\n    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);\n  }\n  \n  .photo {\n    width: 80px;\n    height: 80px;\n    border-radius: 50%;\n    margin-bottom: 10px;\n  }\n  \n  .name {\n    font-size: 1.2rem;\n    font-weight: bold;\n    color: #6a1b9a;\n  }\n  \n  .date {\n    font-size: 0.9rem;\n    color: #888;\n    margin-bottom: 10px;\n  }\n  \n  .review {\n    font-size: 1rem;\n    color: #333;\n    text-align: center;\n    line-height: 1.5;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `TestimonialCard_card__OwiLN`,
	"photo": `TestimonialCard_photo__Pu2iS`,
	"name": `TestimonialCard_name__9ZW01`,
	"date": `TestimonialCard_date__j-O6H`,
	"review": `TestimonialCard_review__MGsyM`
};
export default ___CSS_LOADER_EXPORT___;
