// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HowToMarry_howToMarryContainer__nsIyy {
    width: 100%;
    max-width: 1200px; /* Ограничиваем ширину контента */
    margin: 0 auto;
    text-align: center;
    padding: 40px 20px; /* Отступы */
    background-color: #ffffff; /* Светлый фон */
  }
  
  .HowToMarry_title__Rl4NY {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .HowToMarry_description__HfaOB {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
    line-height: 1.6;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Для маленьких экранов */
  @media (max-width: 768px) {
    .HowToMarry_title__Rl4NY {
      font-size: 2rem;
    }
  
    .HowToMarry_description__HfaOB {
      font-size: 1rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/organism/HowToMarry/HowToMarry.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB,EAAE,iCAAiC;IACpD,cAAc;IACd,kBAAkB;IAClB,kBAAkB,EAAE,YAAY;IAChC,yBAAyB,EAAE,gBAAgB;EAC7C;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA,0BAA0B;EAC1B;IACE;MACE,eAAe;IACjB;;IAEA;MACE,eAAe;IACjB;EACF","sourcesContent":[".howToMarryContainer {\n    width: 100%;\n    max-width: 1200px; /* Ограничиваем ширину контента */\n    margin: 0 auto;\n    text-align: center;\n    padding: 40px 20px; /* Отступы */\n    background-color: #ffffff; /* Светлый фон */\n  }\n  \n  .title {\n    font-size: 2.5rem;\n    font-weight: bold;\n    color: #333;\n    margin-bottom: 20px;\n  }\n  \n  .description {\n    font-size: 1.2rem;\n    color: #555;\n    margin-bottom: 40px;\n    line-height: 1.6;\n    max-width: 900px;\n    margin-left: auto;\n    margin-right: auto;\n  }\n  \n  /* Для маленьких экранов */\n  @media (max-width: 768px) {\n    .title {\n      font-size: 2rem;\n    }\n  \n    .description {\n      font-size: 1rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"howToMarryContainer": `HowToMarry_howToMarryContainer__nsIyy`,
	"title": `HowToMarry_title__Rl4NY`,
	"description": `HowToMarry_description__HfaOB`
};
export default ___CSS_LOADER_EXPORT___;
