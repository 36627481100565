// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BurgerMenuIcon_burgerMenu__FzX14 {
  display: none; /* Hidden by default */
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for open/close state */
}

.BurgerMenuIcon_line__l0y2N {
  background-color: #a45ca3; /* Main color from your project */
  height: 3px;
  border-radius: 2px;
  transition: all 0.3s ease; /* Smooth transition for hover or active states */
}

.BurgerMenuIcon_burgerMenu__FzX14:hover .BurgerMenuIcon_line__l0y2N {
  background-color: #92488f; /* Darker color on hover */
}

/* Display the burger icon on tablets and smartphones */
@media (max-width: 768px) {
  .BurgerMenuIcon_burgerMenu__FzX14 {
    display: flex; /* Show only on tablets and smartphones */
  }
}

/* Transformations for turning the burger into a cross (X) */
.BurgerMenuIcon_burgerMenu__FzX14.BurgerMenuIcon_open__US5ft .BurgerMenuIcon_line__l0y2N:nth-child(1) {
  transform: translateY(8px) rotate(45deg); /* Top line turns into top part of X */
}

.BurgerMenuIcon_burgerMenu__FzX14.BurgerMenuIcon_open__US5ft .BurgerMenuIcon_line__l0y2N:nth-child(2) {
  opacity: 0; /* Middle line disappears */
}

.BurgerMenuIcon_burgerMenu__FzX14.BurgerMenuIcon_open__US5ft .BurgerMenuIcon_line__l0y2N:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg); /* Bottom line turns into bottom part of X */
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/BurgerMenuIcon/BurgerMenuIcon.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE,sBAAsB;EACrC,sBAAsB;EACtB,8BAA8B;EAC9B,WAAW;EACX,YAAY;EACZ,eAAe;EACf,yBAAyB,EAAE,2CAA2C;AACxE;;AAEA;EACE,yBAAyB,EAAE,iCAAiC;EAC5D,WAAW;EACX,kBAAkB;EAClB,yBAAyB,EAAE,iDAAiD;AAC9E;;AAEA;EACE,yBAAyB,EAAE,0BAA0B;AACvD;;AAEA,uDAAuD;AACvD;EACE;IACE,aAAa,EAAE,yCAAyC;EAC1D;AACF;;AAEA,4DAA4D;AAC5D;EACE,wCAAwC,EAAE,sCAAsC;AAClF;;AAEA;EACE,UAAU,EAAE,2BAA2B;AACzC;;AAEA;EACE,0CAA0C,EAAE,4CAA4C;AAC1F","sourcesContent":[".burgerMenu {\n  display: none; /* Hidden by default */\n  flex-direction: column;\n  justify-content: space-between;\n  width: 30px;\n  height: 20px;\n  cursor: pointer;\n  transition: all 0.3s ease; /* Smooth transition for open/close state */\n}\n\n.line {\n  background-color: #a45ca3; /* Main color from your project */\n  height: 3px;\n  border-radius: 2px;\n  transition: all 0.3s ease; /* Smooth transition for hover or active states */\n}\n\n.burgerMenu:hover .line {\n  background-color: #92488f; /* Darker color on hover */\n}\n\n/* Display the burger icon on tablets and smartphones */\n@media (max-width: 768px) {\n  .burgerMenu {\n    display: flex; /* Show only on tablets and smartphones */\n  }\n}\n\n/* Transformations for turning the burger into a cross (X) */\n.burgerMenu.open .line:nth-child(1) {\n  transform: translateY(8px) rotate(45deg); /* Top line turns into top part of X */\n}\n\n.burgerMenu.open .line:nth-child(2) {\n  opacity: 0; /* Middle line disappears */\n}\n\n.burgerMenu.open .line:nth-child(3) {\n  transform: translateY(-8px) rotate(-45deg); /* Bottom line turns into bottom part of X */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"burgerMenu": `BurgerMenuIcon_burgerMenu__FzX14`,
	"line": `BurgerMenuIcon_line__l0y2N`,
	"open": `BurgerMenuIcon_open__US5ft`
};
export default ___CSS_LOADER_EXPORT___;
