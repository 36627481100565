// src/redux/slices/bookingSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PersonDetails {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  // Убрали passportPhoto
}

interface BookingState {
  currentStep: number;
  date: string;
  time: string;
  person1: PersonDetails;
  person2: PersonDetails;
}

const initialState: BookingState = {
  currentStep: 0,
  date: '',
  time: '',
  person1: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    phoneNumber: '',
  },
  person2: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    phoneNumber: '',
  },
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setCurrentStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },
    setDate(state, action: PayloadAction<string>) {
      state.date = action.payload;
    },
    setTime(state, action: PayloadAction<string>) {
      state.time = action.payload;
    },
    setPersonDetails(
      state,
      action: PayloadAction<{ person: 'person1' | 'person2'; details: PersonDetails }>
    ) {
      state[action.payload.person] = action.payload.details;
    },
    resetBooking() {
      return initialState;
    },
  },
});

export const { setCurrentStep, setDate, setTime, setPersonDetails, resetBooking } =
  bookingSlice.actions;

export default bookingSlice.reducer;
