import React from 'react';
import Instructions from '../../molecules/Instructions/Instructions';
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar.svg';
import { ReactComponent as LicenseIcon } from '../../../assets/images/payment.svg';
import { ReactComponent as MarryIcon } from '../../../assets/images/diamond.svg';
import { useDispatch } from 'react-redux';
import { setCurrentStep } from '../../../redux/slices/bookingSlice'; //
import styles from './HowToMarry.module.css';


const stepsData = [
  {
    IconComponent: CalendarIcon,
    stepTitle: 'Step 1. Provide Documents and Choose the Date',
    stepDescription: 'Pick your perfect wedding day yourself on our easy-to-use self-booking calendar.',
    iconColor: '#FF6347', 
    stepAction: () => {} // Оставляем как заглушку, добавим позже
  },
  {
    IconComponent: LicenseIcon, 
    stepTitle: 'Step 2. Get Your License',
    stepDescription: 'Pay for the service (including the government fee).',
    iconLink: '/license',
    iconColor: '#4682B4', 
  },
  {
    IconComponent: MarryIcon, 
    stepTitle: 'Step 3. Get Married!',
    stepDescription: 'Attend your wedding video conference with two witnesses and say "I Do"!',
    iconLink: '/married',
    iconColor: '#92488f',
  },
];

const HowToMarry: React.FC = () => {
  const dispatch = useDispatch();

  const handleOpenForm = () => {
    dispatch(setCurrentStep(1)); // Открываем форму записи при нажатии на шаг "Book Your Date"
  };

  // Заменяем действие для шага "Book Your Date"
  stepsData[0].stepAction = handleOpenForm;
  return (
    <div className={styles.howToMarryContainer}>
      <h1 className={styles.title}>How Do I Get Married Online?</h1>
      <p className={styles.description}>
      Online marriage registration with our with our support lets you tie the knot without leaving 
      your home. Say goodbye to the hassle of traditional paperwork and waiting times—
      we offer a quick, secure, and legally recognized way to get married from anywhere
      in the world. With just a few simple steps, you can obtain your marriage license
      and celebrate your special day with loved ones, no matter where they are. 
      Invite your friends and family to join your virtual ceremony and make your 
      wedding day unforgettable, all while enjoying the comfort and convenience of our 
      online service.
      </p>

      <Instructions steps={stepsData} />
    </div>
  );
};

export default HowToMarry;
