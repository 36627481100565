// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConsultationForm_container__cjX3k {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 0rem 1rem 0rem;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
  }
  
  .ConsultationForm_form__PUzCh {
    width: 100%;
  }
  
  .ConsultationForm_field__7\\+J6F {
    margin-bottom: 1rem;
    width: 100%;
  }
  
  .ConsultationForm_field__7\\+J6F label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
    font-size: 1rem;
  }
  
  .ConsultationForm_error__tRLUT {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  .ConsultationForm_buttons__CpbGl {
    display: flex;
    align-self: flex-end;
    justify-content: center;
    margin-top: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .ConsultationForm_container__cjX3k {
      padding: 15px;
      max-width: 90%;
    }
  }
  
  @media (max-width: 480px) {
    .ConsultationForm_container__cjX3k {
      padding: 10px;
      max-width: 100%;
    }
  
    .ConsultationForm_field__7\\+J6F label {
      font-size: 0.875rem;
    }
  
    .ConsultationForm_error__tRLUT {
      font-size: 0.75rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/organism/ConsultationForm/ConsultationForm.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;IAC9B,4BAA4B;IAC5B,WAAW;IACX,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,qBAAqB;IACrB,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,oBAAoB;IACpB,uBAAuB;IACvB,kBAAkB;EACpB;;EAEA;IACE;MACE,aAAa;MACb,cAAc;IAChB;EACF;;EAEA;IACE;MACE,aAAa;MACb,eAAe;IACjB;;IAEA;MACE,mBAAmB;IACrB;;IAEA;MACE,kBAAkB;IACpB;EACF","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0rem 0rem 1rem 0rem;\n    width: 100%;\n    max-width: 600px;\n    box-sizing: border-box;\n  }\n  \n  .form {\n    width: 100%;\n  }\n  \n  .field {\n    margin-bottom: 1rem;\n    width: 100%;\n  }\n  \n  .field label {\n    font-weight: bold;\n    margin-bottom: 0.5rem;\n    display: block;\n    font-size: 1rem;\n  }\n  \n  .error {\n    color: red;\n    font-size: 0.875rem;\n    margin-top: 0.25rem;\n  }\n  \n  .buttons {\n    display: flex;\n    align-self: flex-end;\n    justify-content: center;\n    margin-top: 1.5rem;\n  }\n  \n  @media (max-width: 768px) {\n    .container {\n      padding: 15px;\n      max-width: 90%;\n    }\n  }\n  \n  @media (max-width: 480px) {\n    .container {\n      padding: 10px;\n      max-width: 100%;\n    }\n  \n    .field label {\n      font-size: 0.875rem;\n    }\n  \n    .error {\n      font-size: 0.75rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ConsultationForm_container__cjX3k`,
	"form": `ConsultationForm_form__PUzCh`,
	"field": `ConsultationForm_field__7+J6F`,
	"error": `ConsultationForm_error__tRLUT`,
	"buttons": `ConsultationForm_buttons__CpbGl`
};
export default ___CSS_LOADER_EXPORT___;
