import React from 'react';
import { ReactComponent as LogoIcon } from '../../../assets/images/LOGO.svg'; // Importing the SVG logo
import styles from './Logo.module.css';

interface LogoProps {
  width?: string; // Optional width for responsiveness
  height?: string; // Optional height for responsiveness
  className?: string; // Additional classes for customization
  onClick?: () => void; // Optional onClick prop 
}

const Logo: React.FC<LogoProps> = ({ width = '100px', onClick, className }) => {
  return (
    <div className={`${styles.logoWrapper} ${styles.navBarLogo} ${className}`} onClick={onClick}>
      <LogoIcon width={width} className={styles.logoIcon} />
    </div>
  );
};

export default Logo;
