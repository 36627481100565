// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TestimonialsGrid_container__YsvUz {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.TestimonialsGrid_title__hz5As {
  font-size: 2rem;
  font-weight: bold;
  color: #6a1b9a;
  margin-bottom: 20px;
}

.TestimonialsGrid_grid__t4VlS {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
}

.TestimonialsGrid_cardWrapper__Z2h06 {
  opacity: 0; /* Начальное состояние — невидимый */
  transform: translateY(20px); /* Легкий сдвиг вниз */
  animation: TestimonialsGrid_fadeIn__lo6rp 0.6s ease forwards; /* Анимация появления */
}

/* Анимация появления */
@keyframes TestimonialsGrid_fadeIn__lo6rp {
  to {
    opacity: 1;
    transform: translateY(0); /* Возврат на место */
  }
}

.TestimonialsGrid_seeMoreButton__NK6uv {
  margin-top: 20px;
  background-color: #6a1b9a;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.TestimonialsGrid_seeMoreButton__NK6uv:hover {
  background-color: #4c1079;
}
`, "",{"version":3,"sources":["webpack://./src/components/organism/TestimonialsGrid/TestimonialsGrid.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,2DAA2D;EAC3D,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,UAAU,EAAE,oCAAoC;EAChD,2BAA2B,EAAE,sBAAsB;EACnD,4DAAoC,EAAE,uBAAuB;AAC/D;;AAEA,uBAAuB;AACvB;EACE;IACE,UAAU;IACV,wBAAwB,EAAE,qBAAqB;EACjD;AACF;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".container {\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 20px;\n  text-align: center;\n}\n\n.title {\n  font-size: 2rem;\n  font-weight: bold;\n  color: #6a1b9a;\n  margin-bottom: 20px;\n}\n\n.grid {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  gap: 20px;\n  justify-items: center;\n}\n\n.cardWrapper {\n  opacity: 0; /* Начальное состояние — невидимый */\n  transform: translateY(20px); /* Легкий сдвиг вниз */\n  animation: fadeIn 0.6s ease forwards; /* Анимация появления */\n}\n\n/* Анимация появления */\n@keyframes fadeIn {\n  to {\n    opacity: 1;\n    transform: translateY(0); /* Возврат на место */\n  }\n}\n\n.seeMoreButton {\n  margin-top: 20px;\n  background-color: #6a1b9a;\n  color: #fff;\n  border: none;\n  border-radius: 5px;\n  padding: 10px 20px;\n  font-size: 1rem;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.seeMoreButton:hover {\n  background-color: #4c1079;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TestimonialsGrid_container__YsvUz`,
	"title": `TestimonialsGrid_title__hz5As`,
	"grid": `TestimonialsGrid_grid__t4VlS`,
	"cardWrapper": `TestimonialsGrid_cardWrapper__Z2h06`,
	"fadeIn": `TestimonialsGrid_fadeIn__lo6rp`,
	"seeMoreButton": `TestimonialsGrid_seeMoreButton__NK6uv`
};
export default ___CSS_LOADER_EXPORT___;
