// HeaderWithBackground.tsx
import React from 'react';
import styles from './HeaderWithBackground.module.css';

interface HeaderWithBackgroundProps {
  backgroundImage: string; // обязательный параметр
  title?: string; // необязательный заголовок
  subtitle?: string; // необязательный подзаголовок
}

const HeaderWithBackground: React.FC<HeaderWithBackgroundProps> = ({ backgroundImage, title, subtitle }) => {
  return (
    <div
      className={styles.headerContainer}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={styles.overlay} />
      {(title || subtitle) && (
        <div className={styles.textOverlay}>
          {title && <h2 className={styles.title}>{title}</h2>}
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </div>
      )}
    </div>
  );
};

export default HeaderWithBackground;
