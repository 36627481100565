// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AppointmentForm.module.css */

.AppointmentForm_appointmentForm__CNjN4 {
  padding: 1.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AppointmentForm_title__Y3pV7 {
  color: #a45ca3;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.AppointmentForm_content__UK0l1 {
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/organism/BookingForms/AppointmentForm/AppointmentForm.module.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;EACE,eAAe;EACf,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB","sourcesContent":["/* AppointmentForm.module.css */\n\n.appointmentForm {\n  padding: 1.5rem;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.title {\n  color: #a45ca3;\n  font-size: 1.8rem;\n  margin-bottom: 0.5rem;\n  text-align: center;\n}\n\n.content {\n  margin-top: 0.5rem;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appointmentForm": `AppointmentForm_appointmentForm__CNjN4`,
	"title": `AppointmentForm_title__Y3pV7`,
	"content": `AppointmentForm_content__UK0l1`
};
export default ___CSS_LOADER_EXPORT___;
