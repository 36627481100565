import React, { useEffect } from 'react';
import CloseButton from '../../atoms/CloseButton/CloseButton';
import HorizontalDivider from '../../atoms/Divider/HorizontalDivider/HorizontalDivider';
import styles from './Popup.module.css';

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children?: React.ReactNode;
}

const Popup: React.FC<PopupProps> = ({ isOpen, onClose, title, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    const handleOutsideClick = (event: MouseEvent) => {
      const popupContent = document.getElementById('popup-content');
      if (popupContent && !popupContent.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.body.style.overflow = '';
    };
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className={styles.overlay}>
          <div id="popup-content" className={styles.popup}>
            <CloseButton onClick={onClose} className={styles.closeButton} />
            {title && <div className={styles.titleContainer}><h2 className={styles.title}>{title}</h2><HorizontalDivider width="80%" /></div>}
            <div className={styles.content}>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
