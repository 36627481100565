// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoCardItem_container__UwJjU {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 0;
    width: 100%;
    text-align: center;
  }
  
  .InfoCardItem_primaryText__eh-7g {
    font-weight: bold;
    color: #333; 
    font-size: 0.9rem;
  }
  .InfoCardItem_secondaryText__aOvGi {
    font-weight: normal;
    color: #999; 
    margin-left: 5px;
  }
  
  .InfoCardItem_line__DupEO {
    width: 80%;
    border: none;
    border-top: 1px solid #d8a7d4; /* Цвет линии в стиле проекта */
    margin-top: 0.3rem;
  }
  
  /* Адаптивные стили для уменьшения размера текста на меньших экранах */
  @media (max-width: 768px) {
    .InfoCardItem_primaryText__eh-7g {
      font-size: 1rem; /* Уменьшение шрифта для планшетов */
    }
    .InfoCardItem_secondaryText__aOvGi {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 450px) {
    .InfoCardItem_primaryText__eh-7g {
      font-size: 0.9rem; /* Ещё меньше для мобильных */
    }
    .InfoCardItem_secondaryText__aOvGi {
      font-size: 0.8rem;
    }
    .InfoCardItem_line__DupEO {
      width: 90%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/atoms/InfoCardItem/InfoCardItem.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,iBAAiB;EACnB;EACA;IACE,mBAAmB;IACnB,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,6BAA6B,EAAE,+BAA+B;IAC9D,kBAAkB;EACpB;;EAEA,sEAAsE;EACtE;IACE;MACE,eAAe,EAAE,oCAAoC;IACvD;IACA;MACE,iBAAiB;IACnB;EACF;;EAEA;IACE;MACE,iBAAiB,EAAE,6BAA6B;IAClD;IACA;MACE,iBAAiB;IACnB;IACA;MACE,UAAU;IACZ;EACF","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 0.3rem 0;\n    width: 100%;\n    text-align: center;\n  }\n  \n  .primaryText {\n    font-weight: bold;\n    color: #333; \n    font-size: 0.9rem;\n  }\n  .secondaryText {\n    font-weight: normal;\n    color: #999; \n    margin-left: 5px;\n  }\n  \n  .line {\n    width: 80%;\n    border: none;\n    border-top: 1px solid #d8a7d4; /* Цвет линии в стиле проекта */\n    margin-top: 0.3rem;\n  }\n  \n  /* Адаптивные стили для уменьшения размера текста на меньших экранах */\n  @media (max-width: 768px) {\n    .primaryText {\n      font-size: 1rem; /* Уменьшение шрифта для планшетов */\n    }\n    .secondaryText {\n      font-size: 0.9rem;\n    }\n  }\n  \n  @media (max-width: 450px) {\n    .primaryText {\n      font-size: 0.9rem; /* Ещё меньше для мобильных */\n    }\n    .secondaryText {\n      font-size: 0.8rem;\n    }\n    .line {\n      width: 90%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `InfoCardItem_container__UwJjU`,
	"primaryText": `InfoCardItem_primaryText__eh-7g`,
	"secondaryText": `InfoCardItem_secondaryText__aOvGi`,
	"line": `InfoCardItem_line__DupEO`
};
export default ___CSS_LOADER_EXPORT___;
