import React from 'react';
import Icon from '../../atoms/Icon/Icon';
import styles from './Step.module.css';

interface StepProps {
  IconComponent: React.FC<React.SVGProps<SVGSVGElement>>; // SVG-компонент передаем напрямую
  stepTitle: string; // Заголовок шага
  stepDescription: string; // Описание шага
  iconLink?: string; // Опциональная ссылка для иконки
  iconColor?: string; // Опциональный цвет иконки
  stepAction?: () => void; // Добавляем опциональное действие для шага
}

const Step: React.FC<StepProps> = ({ IconComponent, stepTitle, stepDescription, iconLink = '#', iconColor = '#a45ca3',stepAction }) => {
  const handleClick = () => {
    if (stepAction) {
      stepAction(); // Если есть действие, выполняем его
    } else {
      window.location.href = iconLink; // Иначе переходим по ссылке
    }
  };

  return (
    <div className={styles.stepContainer} onClick={handleClick}>
      {/* Используем компонент Icon с переданным цветом и SVG */}
      <Icon 
        IconComponent={IconComponent} 
        link={iconLink} 
        color={iconColor} 
        size="3rem" 
        alt={stepTitle} 
      />
      <h2 className={styles.stepTitle}>{stepTitle}</h2>
      <p className={styles.stepDescription}>{stepDescription}</p>
    </div>
  );
};

export default Step;
