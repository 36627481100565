// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Icon_icon__9rdS8 {
  transition: transform 0.3s ease, filter 0.3s ease; /* Плавные переходы для увеличения и осветления */
  cursor: pointer;
  width: 2.5rem; /* Дефолтный размер в rem */
  height: 2.5rem; /* Квадратный вид */
}

/* Эффект при наведении: увеличение и осветление */
.Icon_icon__9rdS8:hover {
  transform: scale(1.2); /* Увеличение на 20% */
  filter: brightness(1.2); /* Осветление иконки */
}

/* Эффект при нажатии: небольшое сжатие */
.Icon_icon__9rdS8:active {
  transform: scale(1.1); /* Уменьшение на 10% при нажатии */
}

/* Медиа-запросы для адаптивности */
@media (max-width: 768px) {
  .Icon_icon__9rdS8 {
    width: 2rem; /* Меньший размер для планшетов */
    height: 2rem;
  }
}

@media (max-width: 450px) {
  .Icon_icon__9rdS8 {
    width: 1.5rem; /* Меньший размер для мобильных устройств */
    height: 1.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/Icon/Icon.module.css"],"names":[],"mappings":"AAAA;EACE,iDAAiD,EAAE,iDAAiD;EACpG,eAAe;EACf,aAAa,EAAE,2BAA2B;EAC1C,cAAc,EAAE,mBAAmB;AACrC;;AAEA,kDAAkD;AAClD;EACE,qBAAqB,EAAE,sBAAsB;EAC7C,uBAAuB,EAAE,sBAAsB;AACjD;;AAEA,yCAAyC;AACzC;EACE,qBAAqB,EAAE,kCAAkC;AAC3D;;AAEA,mCAAmC;AACnC;EACE;IACE,WAAW,EAAE,iCAAiC;IAC9C,YAAY;EACd;AACF;;AAEA;EACE;IACE,aAAa,EAAE,2CAA2C;IAC1D,cAAc;EAChB;AACF","sourcesContent":[".icon {\n  transition: transform 0.3s ease, filter 0.3s ease; /* Плавные переходы для увеличения и осветления */\n  cursor: pointer;\n  width: 2.5rem; /* Дефолтный размер в rem */\n  height: 2.5rem; /* Квадратный вид */\n}\n\n/* Эффект при наведении: увеличение и осветление */\n.icon:hover {\n  transform: scale(1.2); /* Увеличение на 20% */\n  filter: brightness(1.2); /* Осветление иконки */\n}\n\n/* Эффект при нажатии: небольшое сжатие */\n.icon:active {\n  transform: scale(1.1); /* Уменьшение на 10% при нажатии */\n}\n\n/* Медиа-запросы для адаптивности */\n@media (max-width: 768px) {\n  .icon {\n    width: 2rem; /* Меньший размер для планшетов */\n    height: 2rem;\n  }\n}\n\n@media (max-width: 450px) {\n  .icon {\n    width: 1.5rem; /* Меньший размер для мобильных устройств */\n    height: 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `Icon_icon__9rdS8`
};
export default ___CSS_LOADER_EXPORT___;
