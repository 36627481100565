// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container for the instructions */
.Instructions_instructionsContainer__hWN5o {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

/* Wrapper for the steps with flexbox to ensure equal height */
.Instructions_stepsWrapper__3j23b {
  display: flex;
  justify-content: center;
  gap: 2rem; /* Space between steps */

}

/* Responsive layout for small screens */
@media (max-width: 768px) {
  .Instructions_stepsWrapper__3j23b {
    flex-direction: column; /* Stack steps vertically on small screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/Instructions/Instructions.module.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA,8DAA8D;AAC9D;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS,EAAE,wBAAwB;;AAErC;;AAEA,wCAAwC;AACxC;EACE;IACE,sBAAsB,EAAE,4CAA4C;EACtE;AACF","sourcesContent":["/* Container for the instructions */\n.instructionsContainer {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 2rem;\n}\n\n/* Wrapper for the steps with flexbox to ensure equal height */\n.stepsWrapper {\n  display: flex;\n  justify-content: center;\n  gap: 2rem; /* Space between steps */\n\n}\n\n/* Responsive layout for small screens */\n@media (max-width: 768px) {\n  .stepsWrapper {\n    flex-direction: column; /* Stack steps vertically on small screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"instructionsContainer": `Instructions_instructionsContainer__hWN5o`,
	"stepsWrapper": `Instructions_stepsWrapper__3j23b`
};
export default ___CSS_LOADER_EXPORT___;
