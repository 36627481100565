// Subtitle.tsx
import React from 'react';
import styles from './Subtitle.module.css';

interface SubtitleProps {
  text: string;
  className?: string;
}

const Subtitle: React.FC<SubtitleProps> = ({ text }) => {
  return <p className={styles.subtitle}>{text}</p>;
};

export default Subtitle;
