import React from 'react';
import styles from './TextInput.module.css';

interface TextInputProps {
  name: string;
  type: 'text' | 'password' | 'email' | 'tel';
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  type,
  placeholder,
  value,
  onChange,
  disabled = false,
}) => {
  return (
    <input
      className={styles.input}
      type={type}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default TextInput;
