import React, { useState }  from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import AppointmentForm from '../organism/BookingForms/AppointmentForm/AppointmentForm';
import NavBar from '../organism/Navbar/NavBar'; // Импорт NavBar
import styles from './Layout.module.css'; // Импорт CSS Module

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const currentStep = useSelector((state: RootState) => state.booking.currentStep); // Отслеживаем шаг
  const [person1PassportPhoto, setPerson1PassportPhoto] = useState<File | null>(null);
  const [person2PassportPhoto, setPerson2PassportPhoto] = useState<File | null>(null);
  return (
    <div>
      <NavBar />
      <main className={styles.container}> {/* Применяем стили через объект */}
      
        {children}
        {currentStep > 0 && (
          <AppointmentForm
            person1PassportPhoto={person1PassportPhoto}
            person2PassportPhoto={person2PassportPhoto}
            setPerson1PassportPhoto={setPerson1PassportPhoto}
            setPerson2PassportPhoto={setPerson2PassportPhoto}
          />
        )}
        
      </main>
    </div>
  );
};

export default Layout;
