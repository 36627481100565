// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SpecialCardBackground.module.css */

.SpecialCardBackground_cardBackground__zXEIu {
    border-radius: 12px;
    padding: 20px;
    max-width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
    flex-grow: 1;
  }
  
  /* Стиль для выделенной карточки */
  .SpecialCardBackground_highlighted__UN4Ag {
    background-color: #f4e6f7; /* Светло-серый фон */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Мягкая тень */
  }
  
  /* Стиль для невыделенной карточки */
  .SpecialCardBackground_simple__JGr8q {
    background-color: none; /* Белый фон */
    box-shadow: none;
  }
  @media (max-width: 768px){
    .SpecialCardBackground_cardBackground__zXEIu{
      max-width: 300px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/atoms/SpecialCardBackground/SpecialCardBackground.module.css"],"names":[],"mappings":"AAAA,qCAAqC;;AAErC;IACI,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;IAC9B,4DAA4D;IAC5D,YAAY;EACd;;EAEA,kCAAkC;EAClC;IACE,yBAAyB,EAAE,qBAAqB;IAChD,0CAA0C,EAAE,gBAAgB;EAC9D;;EAEA,oCAAoC;EACpC;IACE,sBAAsB,EAAE,cAAc;IACtC,gBAAgB;EAClB;EACA;IACE;MACE,gBAAgB;IAClB;EACF","sourcesContent":["/* SpecialCardBackground.module.css */\n\n.cardBackground {\n    border-radius: 12px;\n    padding: 20px;\n    max-width: 230px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-between;\n    transition: box-shadow 0.3s ease, background-color 0.3s ease;\n    flex-grow: 1;\n  }\n  \n  /* Стиль для выделенной карточки */\n  .highlighted {\n    background-color: #f4e6f7; /* Светло-серый фон */\n    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Мягкая тень */\n  }\n  \n  /* Стиль для невыделенной карточки */\n  .simple {\n    background-color: none; /* Белый фон */\n    box-shadow: none;\n  }\n  @media (max-width: 768px){\n    .cardBackground{\n      max-width: 300px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardBackground": `SpecialCardBackground_cardBackground__zXEIu`,
	"highlighted": `SpecialCardBackground_highlighted__UN4Ag`,
	"simple": `SpecialCardBackground_simple__JGr8q`
};
export default ___CSS_LOADER_EXPORT___;
