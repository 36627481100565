// CloseButton.tsx
import React from 'react';
import styles from './CloseButton.module.css';

interface CloseButtonProps {
  onClick: () => void;
  className?: string; // дополнительный класс для переопределения расположения
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick, className }) => {
  return (
    <button className={`${styles.closeButton} ${className}`} onClick={onClick}>
      <span className={styles.cross}>✕</span>
    </button>
  );
};

export default CloseButton;
