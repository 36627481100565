import React from 'react';
import Step from '../Step/Step';
import styles from './Instructions.module.css';

interface StepData {
  IconComponent: React.FC<React.SVGProps<SVGSVGElement>>;
  stepTitle: string;
  stepDescription: string;
  iconLink?: string;
  iconColor?: string; 
  stepAction?: () => void;
}

interface InstructionsProps {
  steps: StepData[]; 
}

const Instructions: React.FC<InstructionsProps> = ({ steps }) => {
  return (
    <div className={styles.instructionsContainer}>
      <div className={styles.stepsWrapper}>
        {steps.map((step, index) => (
          <Step
            key={index}
            IconComponent={step.IconComponent} 
            stepTitle={step.stepTitle}
            stepDescription={step.stepDescription}
            iconLink={step.iconLink}
            iconColor={step.iconColor} 
            stepAction={step.stepAction}
          />
        ))}
      </div>
    </div>
  );
};

export default Instructions; 