// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Основной контейнер */
.PersonScreen_container__slH1O {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
}

.PersonScreen_form__RYAgV {
  width: 100%;
}

.PersonScreen_field__q2OVM {
  margin-bottom: 1rem;
  width: 100%;
}

.PersonScreen_field__q2OVM label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
  font-size: 1rem;
}

.PersonScreen_error__CUxI6 {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Контейнер для кнопок */
.PersonScreen_buttons__Td9Xt {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  /* width: 100%; */
}

/* Адаптивные стили для планшетов */
@media (max-width: 768px) {
  .PersonScreen_container__slH1O {
    padding: 15px;
    max-width: 90%;
  }

  h2 {
    font-size: 1.5rem;
  }
}

/* Адаптивные стили для маленьких экранов */
@media (max-width: 480px) {
  .PersonScreen_container__slH1O {
    padding: 10px;
    max-width: 100%;
  }

  .PersonScreen_field__q2OVM label {
    font-size: 0.875rem;
  }

  .PersonScreen_error__CUxI6 {
    font-size: 0.75rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/organism/BookingForms/PersonScreen/PersonScreen.module.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,cAAc;EACd,WAAW;EACX,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA,yBAAyB;AACzB;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA,mCAAmC;AACnC;EACE;IACE,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,iBAAiB;EACnB;AACF;;AAEA,2CAA2C;AAC3C;EACE;IACE,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":["/* Основной контейнер */\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 20px;\n  margin: 0 auto;\n  width: 100%;\n  max-width: 600px;\n  box-sizing: border-box;\n}\n\n.form {\n  width: 100%;\n}\n\n.field {\n  margin-bottom: 1rem;\n  width: 100%;\n}\n\n.field label {\n  font-weight: bold;\n  margin-bottom: 0.5rem;\n  display: block;\n  font-size: 1rem;\n}\n\n.error {\n  color: red;\n  font-size: 0.875rem;\n  margin-top: 0.25rem;\n}\n\n/* Контейнер для кнопок */\n.buttons {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 1.5rem;\n  /* width: 100%; */\n}\n\n/* Адаптивные стили для планшетов */\n@media (max-width: 768px) {\n  .container {\n    padding: 15px;\n    max-width: 90%;\n  }\n\n  h2 {\n    font-size: 1.5rem;\n  }\n}\n\n/* Адаптивные стили для маленьких экранов */\n@media (max-width: 480px) {\n  .container {\n    padding: 10px;\n    max-width: 100%;\n  }\n\n  .field label {\n    font-size: 0.875rem;\n  }\n\n  .error {\n    font-size: 0.75rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonScreen_container__slH1O`,
	"form": `PersonScreen_form__RYAgV`,
	"field": `PersonScreen_field__q2OVM`,
	"error": `PersonScreen_error__CUxI6`,
	"buttons": `PersonScreen_buttons__Td9Xt`
};
export default ___CSS_LOADER_EXPORT___;
