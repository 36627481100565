// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Main NavBar styles */
.NavBar_navBar__jd5IB {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  padding: 0 1rem;
  background-color: #faf5fae8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.NavBar_navBarContent__oLHUM {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  height: 100%;
}

.NavBar_logoContainer__U4TCg {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}

.NavBar_navLinks__XYJJ7 {
  display: flex;
  gap: 1rem;
  height: 100%;
}

@media (max-width: 768px) {
  .NavBar_navLinks__XYJJ7 {
    display: none;
  }
}

/* Burger Button Wrapper */
.NavBar_burgerButtonWrapper__4vI17 {
  margin-top: 3rem; /* Отодвигаем кнопку вниз */
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/organism/Navbar/NavBar.module.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,2BAA2B;EAC3B,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,YAAY;AACd;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA,0BAA0B;AAC1B;EACE,gBAAgB,EAAE,2BAA2B;EAC7C,aAAa;EACb,uBAAuB;AACzB","sourcesContent":["/* Main NavBar styles */\n.navBar {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 4rem;\n  padding: 0 1rem;\n  background-color: #faf5fae8;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  z-index: 1000;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.navBarContent {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  max-width: 1200px;\n  width: 100%;\n  height: 100%;\n}\n\n.logoContainer {\n  display: flex;\n  align-items: center;\n  padding: 0.5rem 0;\n}\n\n.navLinks {\n  display: flex;\n  gap: 1rem;\n  height: 100%;\n}\n\n@media (max-width: 768px) {\n  .navLinks {\n    display: none;\n  }\n}\n\n/* Burger Button Wrapper */\n.burgerButtonWrapper {\n  margin-top: 3rem; /* Отодвигаем кнопку вниз */\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navBar": `NavBar_navBar__jd5IB`,
	"navBarContent": `NavBar_navBarContent__oLHUM`,
	"logoContainer": `NavBar_logoContainer__U4TCg`,
	"navLinks": `NavBar_navLinks__XYJJ7`,
	"burgerButtonWrapper": `NavBar_burgerButtonWrapper__4vI17`
};
export default ___CSS_LOADER_EXPORT___;
