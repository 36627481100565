// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Paragraph_paragraph__AiUiM {
  font-size: 1rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

.Paragraph_link__31ygc {
  color: #a45ca3; /* Цвет ссылок */
  text-decoration: underline;
  transition: color 0.3s;
}

.Paragraph_link__31ygc:hover {
  color: #6a1b9a; /* Темный фиолетовый при наведении */
}

.Paragraph_small__PdS0e {
  font-size: 0.8rem; /* Уменьшенный размер шрифта */
  color: #666; /* Более светлый цвет для дополнительной информации */
  text-align: left; /* Выравнивание по левому краю для примечаний */
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/TextComponents/Paragraph/Paragraph.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,cAAc,EAAE,gBAAgB;EAChC,0BAA0B;EAC1B,sBAAsB;AACxB;;AAEA;EACE,cAAc,EAAE,oCAAoC;AACtD;;AAEA;EACE,iBAAiB,EAAE,8BAA8B;EACjD,WAAW,EAAE,qDAAqD;EAClE,gBAAgB,EAAE,+CAA+C;AACnE","sourcesContent":[".paragraph {\n  font-size: 1rem;\n  color: #333;\n  margin-bottom: 1rem;\n  text-align: center;\n}\n\n.link {\n  color: #a45ca3; /* Цвет ссылок */\n  text-decoration: underline;\n  transition: color 0.3s;\n}\n\n.link:hover {\n  color: #6a1b9a; /* Темный фиолетовый при наведении */\n}\n\n.small {\n  font-size: 0.8rem; /* Уменьшенный размер шрифта */\n  color: #666; /* Более светлый цвет для дополнительной информации */\n  text-align: left; /* Выравнивание по левому краю для примечаний */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paragraph": `Paragraph_paragraph__AiUiM`,
	"link": `Paragraph_link__31ygc`,
	"small": `Paragraph_small__PdS0e`
};
export default ___CSS_LOADER_EXPORT___;
