import React from "react";
import ContentWrapper from "../components/molecules/ContentWrapper/ContentWrapper";
import HeaderWithBackground from "../components/molecules/HeaderWithBackground/HeaderWithBackground";
import InfoLicense from "../components/molecules/InfoLicense/InfoLicense";
import licenseBack from '../assets/images/license_back.jpg'
export function LicensePage(){ 
    return(
        <div className='homeContainer'>
          <HeaderWithBackground backgroundImage={licenseBack} title="Step 2 – Get your marriage license"/>
          <ContentWrapper>
          <InfoLicense/>
          </ContentWrapper>        
        </div>
        );
    } 