import React from 'react';
import styles from './NavLink.module.css';

interface NavLinkProps {
  text: string;
  href: string;
  isBurgerMenu?: boolean;
  onClick?: () => void;
}

const NavLink: React.FC<NavLinkProps> = ({ text, href, isBurgerMenu = false, onClick }) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      e.preventDefault(); 
      onClick();
    }
  };

  return (
    <a
      href={href}
      className={`${styles.navLink} ${isBurgerMenu ? styles.burgerMenuLink : styles.desktopLink}`}
      onClick={handleClick}
    >
      {text}
      {!isBurgerMenu && <span className={styles.hoverBackground}></span>}
    </a>
  );
};

export default NavLink;
