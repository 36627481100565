import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPersonDetails, setCurrentStep } from '../../../../redux/slices/bookingSlice';
import { RootState } from '../../../../redux/store';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import TextInput from '../../../atoms/Input/TextInput';
import DateInput from '../../../atoms/Input/DateInput';
import FileUploadForm from '../../../molecules/FileUploadForm/FileUploadForm';
import Button from '../../../atoms/Button/Button';
import SubmitButton from '../../../atoms/SubmitButton/SubmitButton';
import BackButton from '../../../atoms/Button/BackButton/BackButton';
import styles from './PersonScreen.module.css';

interface PersonScreenProps {
  person: 'person1' | 'person2';
  setPassportPhoto: (file: File | null) => void;
  passportPhoto: File | null;
}

const PersonScreen: React.FC<PersonScreenProps> = ({ person, setPassportPhoto, passportPhoto }) => {
  const dispatch = useDispatch();
  const personData = useSelector((state: RootState) => state.booking[person]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required')
      .matches(/^[A-Za-z]+$/, 'Only Latin letters are allowed'),
    lastName: Yup.string()
      .required('Last name is required')
      .matches(/^[A-Za-z]+$/, 'Only Latin letters are allowed'),
      dateOfBirth: Yup.date()
      .required('Date of birth is required')
      .test(
        'age-minimum',
        'You must be at least 16 years old',
        (value) => {
          if (!value) return false;
          const today = new Date();
          const sixteenYearsAgo = new Date(today.getFullYear() - 16, today.getMonth(), today.getDate());
          return value <= sixteenYearsAgo;
        }
      ),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^\+?[0-9]{7,15}$/, 'Invalid phone number'),
    passportPhoto: Yup.mixed()
      .required('Passport photo is required')
      .test('fileSize', 'File too large', (value) => {
        if (value && value instanceof File) {
          return value.size <= 20 * 1024 * 1024;
        }
        return false;
      })
      .test('fileFormat', 'Unsupported Format', (value) => {
        if (value && value instanceof File) {
          return ['image/jpeg', 'image/png'].includes(value.type);
        }
        return false;
      }),
  });

  return (
    <div className={styles.container}>
      <Formik
        initialValues={{
          firstName: personData.firstName,
          lastName: personData.lastName,
          dateOfBirth: personData.dateOfBirth,
          phoneNumber: personData.phoneNumber,
          passportPhoto: passportPhoto || null,
        }}
        validationSchema={validationSchema}
        
        onSubmit={(values) => {
          const { passportPhoto, ...rest } = values;
          dispatch(setPersonDetails({ person, details: rest }));
          setPassportPhoto(passportPhoto);
          dispatch(setCurrentStep(person === 'person1' ? 3 : 4));
        }}
      >
        {({ setFieldValue }) => (
          <Form className={styles.form}>
            <div className={styles.field}>
              <label>First Name</label>
              <Field name="firstName">
                {({ field }: any) => (
                  <TextInput
                    {...field}
                    name="firstName"
                    type="text"
                    placeholder="Enter your first name"
                  />
                )}
              </Field>
              <ErrorMessage name="firstName" component="div" className={styles.error} />
            </div>
            <div className={styles.field}>
              <label>Last Name</label>
              <Field name="lastName">
                {({ field }: any) => (
                  <TextInput
                    {...field}
                    name="lastName"
                    type="text"
                    placeholder="Enter your last name"
                  />
                )}
              </Field>
              <ErrorMessage name="lastName" component="div" className={styles.error} />
            </div>
            <div className={styles.field}>
              <label>Date of Birth</label>
              <Field name="dateOfBirth">
                {({ field }: any) => (
                  <DateInput
                    {...field}
                    name="dateOfBirth"
                    onChange={(e) => setFieldValue('dateOfBirth', e.target.value)}
                  />
                )}
              </Field>
              <ErrorMessage name="dateOfBirth" component="div" className={styles.error} />
            </div>
            <div className={styles.field}>
              <label>Phone Number</label>
              <Field name="phoneNumber">
                {({ field }: any) => (
                  <TextInput
                    {...field}
                    name="phoneNumber"
                    type="tel"
                    placeholder="Enter your phone number"
                  />
                )}
              </Field>
              <ErrorMessage name="phoneNumber" component="div" className={styles.error} />
            </div>
            <div className={styles.field}>
              <label>Passport Photo</label>
              <FileUploadForm
                onFileSelect={(file: File) => {
                  setFieldValue('passportPhoto', file);
                  setPassportPhoto(file);
                }}
                existingFile={passportPhoto}
              />
              <ErrorMessage name="passportPhoto" component="div" className={styles.error} />
            </div>
            <div className={styles.buttons}>
              <Button
                onClick={() => dispatch(setCurrentStep(person === 'person1' ? 1 : 2))}
                label='Back'
              />
              <SubmitButton label="Next" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonScreen;
