import React, { useState } from 'react';
import styles from './LanguageSwitcher.module.css';

interface LanguageSwitcherProps {
  onLanguageChange: (language: string) => void; // Callback to handle language change
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ onLanguageChange }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<'EN' | 'HE'>('EN'); // Default language is English

  const toggleLanguage = () => {
    const newLanguage = selectedLanguage === 'EN' ? 'HE' : 'EN';
    setSelectedLanguage(newLanguage);
    onLanguageChange(newLanguage); // Call the parent handler with the selected language
  };

  return (
    <div className={`${styles.switcher} ${selectedLanguage === 'HE' ? styles.he : styles.en}`} onClick={toggleLanguage}>
      {/* Language options */}
      <div className={`${styles.language} ${selectedLanguage === 'EN' ? styles.active : ''}`}>EN</div>
      <div className={`${styles.language} ${selectedLanguage === 'HE' ? styles.active : ''}`}>HE</div>
    </div>
  );
};

export default LanguageSwitcher;
