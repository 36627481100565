// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* InfoLicense.module.css */

.InfoLicense_infoLicense__VU2Nj {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.InfoLicense_introSection__kXMXj {
  margin-bottom: 2rem;
}

.InfoLicense_introSection__kXMXj p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.InfoLicense_introSection__kXMXj p:first-child {
  font-weight: bold;
  color: #333; /* Темный цвет для акцентов */
}

.InfoLicense_introSection__kXMXj a {
  color: #6a1b9a; /* Ссылки фиолетового цвета */
  text-decoration: underline;
}

.InfoLicense_introSection__kXMXj a:hover {
  color: #a45ca3;
}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/InfoLicense/InfoLicense.module.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,WAAW;EACX,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,WAAW,EAAE,6BAA6B;AAC5C;;AAEA;EACE,cAAc,EAAE,6BAA6B;EAC7C,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* InfoLicense.module.css */\n\n.infoLicense {\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 20px;\n  color: #333;\n  font-family: 'Arial', sans-serif;\n}\n\n.introSection {\n  margin-bottom: 2rem;\n}\n\n.introSection p {\n  margin-bottom: 1rem;\n  line-height: 1.6;\n}\n\n.introSection p:first-child {\n  font-weight: bold;\n  color: #333; /* Темный цвет для акцентов */\n}\n\n.introSection a {\n  color: #6a1b9a; /* Ссылки фиолетового цвета */\n  text-decoration: underline;\n}\n\n.introSection a:hover {\n  color: #a45ca3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoLicense": `InfoLicense_infoLicense__VU2Nj`,
	"introSection": `InfoLicense_introSection__kXMXj`
};
export default ___CSS_LOADER_EXPORT___;
