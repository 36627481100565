import React from "react";
import Title from "../../atoms/TextComponents/Title/Title";
import HorizontalDivider from "../../atoms/Divider/HorizontalDivider/HorizontalDivider";
import AddOnItem from "../../molecules/AddOnItem/AddOnItem";
import styles from "./AddOnsSection.module.css";

interface AddOnsSectionProps {
  items: {
    primaryText: string;
    secondaryText?: string;
  }[];
}

const AddOnsSection: React.FC<AddOnsSectionProps> = ({ items }) => {
  return (
    <div className={styles.addOnsSection}>
      <Title text="Add-Ons" className={styles.addOnsTitle} />
      <HorizontalDivider width="80%" />
      <ul className={styles.list}>
        {items.map((item, index) => (
          <AddOnItem
            key={index}
            primaryText={item.primaryText}
            secondaryText={item.secondaryText}
          />
        ))}
      </ul>
    </div>
  );
};

export default AddOnsSection;
