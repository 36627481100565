import React from 'react';
import Title from '../../atoms/TextComponents/Title/Title';
import Subtitle from '../../atoms/TextComponents/Subtitle/Subtitle';
import Paragraph from '../../atoms/TextComponents/Paragraph/Paragraph';
import List from '../../atoms/TextComponents/List/List';
import styles from './InfoLicense.module.css';

const InfoLicense = () => {
  const listItems = [
    {
      primaryText: "Verify your email address",
      secondaryText: "Both members of the couple must verify their individual email address.",
    },
    {
      primaryText: "Verify your Identity",
      secondaryText: "Take a photo of your ID document (must be a live photo).",
    },
    {
      primaryText: "Take a selfie",
      secondaryText: "This will be compared to your ID document to verify you (must be a live photo).",
    },
    {
      primaryText: "Fill in your information",
      secondaryText: "Your details about where you live, where you were born, your parents' names and other statistics will be collected.",
    },
    {
      primaryText: "Send your license to your officiant",
      secondaryText: "Email to us at license@utahweddings.online – (not .com).",
    },
    {
      primaryText: "Create your digital signature",
      secondaryText: "Sign your license.",
    },
    {
      primaryText: "Add Payment and Shipping information",
      secondaryText: "Domestic expedited and International shipping is available. See their fee schedule here.",
      isLink: true,
      linkUrl: "https://example.com/payment",
    },
  ];

  return (
    <section className={styles.infoLicense}>
      <div className={styles.introSection}>
        <Paragraph text="Utah County has completely revamped their marriage license process!" />
        <Paragraph text="This is great news for couples, as it allows independent entry of information for each member of the couple." />
        <Paragraph
          text="Start your online marriage license application now!"
          isLink={true}
          linkUrl="https://example.com/application"
        />
        <Paragraph text="Very Important – A new step in this process asks for your wedding officiant’s email address – ours is license@utahweddings.online." />
        <Paragraph text="Please note that we don’t have .com at the end of that email address! We use the .online domain for emails. You must use this address to send your marriage license to Rev. Heron for your ceremony!" />
      </div>
      <Title text="How to Apply for your Utah County Marriage License" />
      <Subtitle text="The new Utah County marriage license application involves the following steps:" />
      <List items={listItems} />
    </section>
  );
};

export default InfoLicense;
