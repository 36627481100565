import React from 'react';
import styles from './AlertChoiceBox.module.css';

interface AlertChoiceBoxProps {
  question: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const AlertChoiceBox: React.FC<AlertChoiceBoxProps> = ({ question, onConfirm, onCancel }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.alertBox}>
        <p className={styles.question}>{question}</p>
        <div className={styles.buttons}>
          <button className={styles.cancelButton} onClick={onCancel}>No</button>
          <button className={styles.confirmButton} onClick={onConfirm}>Yes</button>
        </div>
      </div>
    </div>
  );
};

export default AlertChoiceBox;
