import React from "react";
import styles from "./AddOnItem.module.css";

interface AddOnItemProps {
  primaryText: string;
  secondaryText?: string;
}

const AddOnItem: React.FC<AddOnItemProps> = ({ primaryText, secondaryText }) => {
  return (
    <li className={styles.addOnItem}>
      <span className={styles.primaryText}>{primaryText}</span>
      {secondaryText && <span className={styles.secondaryText}>{` ${secondaryText}`}</span>}
    </li>
  );
};

export default AddOnItem;
