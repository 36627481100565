import React from 'react';
import { ReactComponent as DefaultIcon } from '../../../assets/images/calendar.svg'; // Импортируем SVG как компонент
import styles from './Icon.module.css';

interface IconProps {
  IconComponent?: React.FC<React.SVGProps<SVGSVGElement>>; // SVG компонент
  alt?: string; // Альтернативный текст
  size?: string; // Размер иконки (по умолчанию 2.5rem)
  link?: string; // Ссылка для перехода при клике
  color?: string; // Цвет иконки
}

const Icon: React.FC<IconProps> = ({ IconComponent = DefaultIcon, alt = 'icon', size = '2.5rem', link, color = '#FF6347' }) => {
  const handleClick = () => {
    if (link) {
      window.location.href = link;
    }
  };

  return (
    <div
      className={styles.iconWrapper}
      onClick={handleClick}
      style={{ width: size, height: size }}
    >
      {/* SVG иконка, с возможностью изменения цвета */}
      <IconComponent
        className={styles.icon}
        style={{ stroke: color, width: '100%', height: '100%' }}
        aria-label={alt}
      />
    </div>
  );
};

export default Icon;
