import React, { useEffect } from 'react';
import styles from './SlidingMenu.module.css';

interface SlidingMenuProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

const SlidingMenu: React.FC<SlidingMenuProps> = ({ isOpen, onClose, children }) => {
  // Close the menu when clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const menu = document.getElementById('sliding-menu');
      if (menu && !menu.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [isOpen, onClose]);

  return (
    <>
      {/* Overlay */}
      {isOpen && <div className={styles.overlay} onClick={onClose}></div>}

      {/* Sliding Menu */}
      <div id="sliding-menu" className={`${styles.menu} ${isOpen ? styles.open : ''}`}>
        {/* Close button (cross) */}
        <button className={styles.closeButton} onClick={onClose}>
          &times; {/* HTML entity for '×' cross symbol */}
        </button>

        {/* Menu content */}
        {children}
      </div>
    </>
  );
};

export default SlidingMenu;
