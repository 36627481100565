import React from "react";
import ContentWrapper from "../components/molecules/ContentWrapper/ContentWrapper";
import HeaderWithBackground from "../components/molecules/HeaderWithBackground/HeaderWithBackground";
import InfoMarried from "../components/molecules/InfoMarried/InfoMarried";
import Button from "../components/atoms/Button/Button";
import marriedBack from '../assets/images/married_back.jpg';

export function GetMarriedPage() {
    return (
        <div className='homeContainer'>
            <HeaderWithBackground 
                backgroundImage={marriedBack} 
                title="Get married with us!" 
            />
            <ContentWrapper>
                <InfoMarried />
            </ContentWrapper>
        </div>
    );
}
