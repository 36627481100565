import React from 'react';
import Title from '../../atoms/TextComponents/Title/Title';
import Subtitle from '../../atoms/TextComponents/Subtitle/Subtitle';
import Paragraph from '../../atoms/TextComponents/Paragraph/Paragraph';
import styles from './InfoAboutUs.module.css';
import teamAvatar from '../../../assets/images/team_avatar.jpg';

const InfoAboutUs = () => {
    return (
        <section className={styles.infoAboutUs}>
            <div className={styles.avatarContainer}>
                <img
                    src={teamAvatar}
                    alt="Our Team"
                    className={styles.avatar}
                />
                <div className={styles.contactDetails}>
                    <p><strong>Contact Us:</strong></p>
                    <p>Email: <a href="mailto:info@weddingservices.com">info@weddingservices.com</a></p>
                    <p>Phone: <a href="tel:+123456789">+1 (234) 567-89</a></p>
                    <p>Working Hours:</p>
                    <p>Monday - Friday: 9 AM - 6 PM</p>
                    <p>Saturday: 10 AM - 4 PM</p>
                    <p>Sunday: Closed</p>
                </div>
            </div>
            <div className={styles.textContainer}>
                <Title text="Meet Our Team" />
                <Subtitle text="Dedicated to Making Your Special Day Perfect" />
                <Paragraph
                    text="Welcome to our online wedding service! We are a passionate team dedicated to helping couples from all over the world celebrate their love and make their dreams come true."
                />
                <Paragraph
                    text="Our mission is simple: to provide an inclusive, accessible, and seamless experience for every couple who wishes to formalize their relationship. We understand that love knows no boundaries, and we’re here to ensure that every couple, regardless of location, can create unforgettable moments together."
                />
                <Paragraph
                    text="With years of experience in the wedding industry, we’ve learned the importance of personalization and care. Each member of our team is committed to ensuring your special day is as unique and memorable as your love story."
                />
                <Paragraph
                    text="From navigating international legal requirements to planning the perfect ceremony, we’ve got you covered. Our goal is to take the stress out of the process, so you can focus on what truly matters: celebrating your love."
                />
                <Paragraph
                    text="Let us be part of your journey and help you say “I do” in a way that’s meaningful, heartfelt, and unforgettable."
                />
            </div>
        </section>
    );
};

export default InfoAboutUs;
