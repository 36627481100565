import React from 'react';
import styles from './TestimonialCard.module.css';

interface TestimonialCardProps {
  photo: string;
  name: string;
  date: string;
  review: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ photo, name, date, review }) => {
  return (
    <div className={styles.card}>
      <img src={photo} alt={`${name}'s testimonial`} className={styles.photo} />
      <h3 className={styles.name}>{name}</h3>
      <p className={styles.date}>{date}</p>
      <p className={styles.review}>{review}</p>
    </div>
  );
};

export default TestimonialCard;
