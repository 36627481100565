  import React from "react";
  import Title from "../../atoms/TextComponents/Title/Title";
  import List from "../../atoms/TextComponents/List/List";
  import HorizontalDivider from "../../atoms/Divider/HorizontalDivider/HorizontalDivider";
  import PackageProduct from "../../molecules/PackageProduct/PackageProduct";
  import AddOnsSection from "../AddOnsSection/AddOnsSection";
  import styles from "./InfoPricing.module.css";

  const InfoPricing = () => {
    const basePackageItems = [
      { primaryText: "100% Satisfaction Guarantee" },
      { primaryText: "Wedding Ceremony with Experienced Officiant" },
      { primaryText: "Premium Concierge Support at Every Step" },
      { primaryText: "Physical and Digital Marriage Certificate" },
      { primaryText: "Expert Advice for Marriage Recognition in 150+ countries" },
      { primaryText: "Video Recording of Your Ceremony" },
      { primaryText: "Cloud Storage of Your Video Recording" },
      { primaryText: "Get a Full Refund if You Change Your Mind" },
    ];

    const premiumPackageItems = [
      { primaryText: "All Benefits from The Base Package", withHeart: true },
      { primaryText: "Priority Ceremony Scheduling", withHeart: true },
      { primaryText: "Personalized Wedding Vows Assistance", withHeart: true },
      { primaryText: "Dedicated Support Team for Your Ceremony", withHeart: true },
      { primaryText: "Custom Designed Digital Invitations", withHeart: true },
      { primaryText: "High-Quality Edited Video of Your Ceremony", withHeart: true },
      { primaryText: "Private Online Album for Your Wedding Memories", withHeart: true },
    ];

    const addOnsItems = [
      {
        primaryText: "200 NIS",
        secondaryText: "Two Witnesses – We provide your required witnesses.",
      },
      {
        primaryText: "200 NIS (plus shipping)",
        secondaryText: "Apostille Stamp – Allows the marriage certificate to be recognized internationally.",
      },
      {
        primaryText: "1000 NIS (average price)",
        secondaryText: "Embassy Authentication Package – Allows the marriage certificate to be recognized in countries that do not accept Apostilles.",
      },
      {
        primaryText: "1000 NIS (average price)",
        secondaryText: "Expedited Apostille Stamp – Cuts processing time in half.",
      },
      {
        primaryText: "700 NIS",
        secondaryText: "Rush Processing – Have your online ceremony within 24 hours of your marriage license approval or get your money back.",
      },
      {
        primaryText: "150 NIS",
        secondaryText: "Live Translation – Available in Spanish, Italian, French, Chinese, Portuguese, Filipino, & more.",
      },
    ];

    return (
      <section className={styles.infoPricing}>
        {/* Base Package Section */}
        <div className={styles.packageSection}>
          <PackageProduct
            title="The Base Package"
            price="3000 NIS"
            items={basePackageItems}
            notes={[
              "* You Must Provide 2 Witnesses",
              "** Shipping Outside the USA Not Included",
            ]}
          />
          <PackageProduct
            title="The Premium Package"
            price="5000 NIS"
            items={premiumPackageItems}
            notes={[
              "* Priority Scheduling is subject to availability.",
              "** Additional customization may incur extra charges.",
            ]}
          />
        </div>

        {/* Add-Ons Section */}
        <AddOnsSection items={addOnsItems}/>
      </section>
    );
  };

  export default InfoPricing;
