// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavBarRightPanel_rightPanel__wZ4uH {
    display: flex;
    align-items: center; /* Align all elements vertically */
    justify-content: flex-end;
    gap: 20px; /* Spacing between elements */
  }
  
  .NavBarRightPanel_item__QPEOa {
    display: flex;
    align-items: center;
    height: 40px; /* Ensure all items have the same height */
  }
  
  .NavBarRightPanel_additionalItems__je7Id {
    display: flex;
    align-items: center;
    height: 40px; /* Ensures any additional elements have the same height */
  }
  
  /* Responsive behavior: align elements properly for tablet and mobile */
  @media (max-width: 768px) {
    .NavBarRightPanel_rightPanel__wZ4uH {
      gap: 15px; /* Slightly reduce gap on smaller screens */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/molecules/NavBarRightPanel/NavBarRightPanel.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB,EAAE,kCAAkC;IACvD,yBAAyB;IACzB,SAAS,EAAE,6BAA6B;EAC1C;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,YAAY,EAAE,0CAA0C;EAC1D;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,YAAY,EAAE,yDAAyD;EACzE;;EAEA,uEAAuE;EACvE;IACE;MACE,SAAS,EAAE,2CAA2C;IACxD;EACF","sourcesContent":[".rightPanel {\n    display: flex;\n    align-items: center; /* Align all elements vertically */\n    justify-content: flex-end;\n    gap: 20px; /* Spacing between elements */\n  }\n  \n  .item {\n    display: flex;\n    align-items: center;\n    height: 40px; /* Ensure all items have the same height */\n  }\n  \n  .additionalItems {\n    display: flex;\n    align-items: center;\n    height: 40px; /* Ensures any additional elements have the same height */\n  }\n  \n  /* Responsive behavior: align elements properly for tablet and mobile */\n  @media (max-width: 768px) {\n    .rightPanel {\n      gap: 15px; /* Slightly reduce gap on smaller screens */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rightPanel": `NavBarRightPanel_rightPanel__wZ4uH`,
	"item": `NavBarRightPanel_item__QPEOa`,
	"additionalItems": `NavBarRightPanel_additionalItems__je7Id`
};
export default ___CSS_LOADER_EXPORT___;
