import React, { useState } from "react";
import Logo from "../../atoms/Logo/Logo";
import NavLink from "../../atoms/NavLink/NavLink";
import NavBarRightPanel from "../../molecules/NavBarRightPanel/NavBarRightPanel";
import SlidingMenu from "../../molecules/SlidingMenu/SlidingMenu";
import { useDispatch } from "react-redux";
import { setCurrentStep } from "../../../redux/slices/bookingSlice";
import Button from "../../atoms/Button/Button";
import styles from "./NavBar.module.css";

const NavBar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleLanguageChange = (language: string) => {
    console.log("Selected language:", language);
  };

  const handleOpenForm = () => {
    dispatch(setCurrentStep(1));
  };

  const handleOpenFormBurger = () => {
    toggleMenu();
    handleOpenForm();
  };

  return (
    <>
      <header className={styles.navBar}>
        <div className={styles.navBarContent}>
          {/* Logo */}
          <div className={styles.logoContainer}>
            <Logo onClick={() => (window.location.href = "/")} />
          </div>

          {/* Desktop Navigation */}
          <nav className={styles.navLinks}>
            <NavLink text="Home" href="/" />
            <NavLink text="Get married!" href="#" onClick={handleOpenForm} />
            <NavLink text="Pricing" href="/pricing" />
            <NavLink text="About Us" href="/about" />
          </nav>

          {/* Right Panel */}
          <NavBarRightPanel
            isMenuOpen={isMenuOpen}
            onBurgerClick={toggleMenu}
            onLanguageChange={handleLanguageChange}
          />
        </div>
      </header>

      {/* Sliding Menu */}
      <SlidingMenu isOpen={isMenuOpen} onClose={closeMenu}>
        <NavLink text="Home" href="/" isBurgerMenu />
        <NavLink text="Pricing" href="/pricing" isBurgerMenu />
        <NavLink text="About Us" href="/about" isBurgerMenu />
        <div className={styles.burgerButtonWrapper}>
          <Button label="Get married!" onClick={handleOpenFormBurger} />
        </div>
      </SlidingMenu>
    </>
  );
};

export default NavBar;
