// AppointmentForm.tsx
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/store';
import CalendarScreen from '../CalendarScreen/CalendarScreen';
import PersonScreen from '../PersonScreen/PersonScreen';
import Confirmation from '../Confirmation/Confirmation';
import Popup from '../../../molecules/Popup/Popup';
import { setCurrentStep } from '../../../../redux/slices/bookingSlice';
import AlertChoiceBox from '../../../molecules/AlertChoiceBox/AlertChoiceBox';
import HorizontalDivider from '../../../atoms/Divider/HorizontalDivider/HorizontalDivider';
import styles from './AppointmentForm.module.css';

interface AppointmentFormProps {
  person1PassportPhoto: File | null;
  person2PassportPhoto: File | null;
  setPerson1PassportPhoto: (file: File | null) => void;
  setPerson2PassportPhoto: (file: File | null) => void;
}

const AppointmentForm: React.FC<AppointmentFormProps> = ({
  person1PassportPhoto,
  person2PassportPhoto,
  setPerson1PassportPhoto,
  setPerson2PassportPhoto,
}) => {
  const dispatch = useDispatch();
  const currentStep = useSelector((state: RootState) => state.booking.currentStep);
  const isOpen = currentStep > 0;

  const [showAlert, setShowAlert] = useState(false);

  const handleCloseClick = () => {
    setShowAlert(true);
  };

  const handleConfirmClose = () => {
    setShowAlert(false);
    dispatch(setCurrentStep(0));
  };

  const handleCancelClose = () => {
    setShowAlert(false);
  };

  // Function to get the title based on the current step
  const getStepTitle = () => {
    switch (currentStep) {
      case 1:
        return "Select a Date & Time";
      case 2:
        return "Person 1 Details";
      case 3:
        return "Person 2 Details";
      case 4:
        return "Confirmation";
      default:
        return "";
    }
  };

  return (
    <>
      <Popup isOpen={isOpen} onClose={handleCloseClick}>
        <div className={styles.appointmentForm}>
          {/* Title based on the current step */}
          {isOpen && (
            <>
              <h2 className={styles.title}>{getStepTitle()}</h2>
              <HorizontalDivider width="80%" /> {/* Добавленный разделитель */}
            </>
          )}
          {currentStep === 1 && <CalendarScreen />}
          {currentStep === 2 && (
            <PersonScreen
              person="person1"
              setPassportPhoto={setPerson1PassportPhoto}
              passportPhoto={person1PassportPhoto}
            />
          )}
          {currentStep === 3 && (
            <PersonScreen
              person="person2"
              setPassportPhoto={setPerson2PassportPhoto}
              passportPhoto={person2PassportPhoto}
            />
          )}
          {currentStep === 4 && (
            <Confirmation
              person1PassportPhoto={person1PassportPhoto}
              person2PassportPhoto={person2PassportPhoto}
            />
          )}
        </div>
      </Popup>

      {showAlert && (
        <AlertChoiceBox
          question="Are you sure you want to close the form?"
          onConfirm={handleConfirmClose}
          onCancel={handleCancelClose}
        />
      )}
    </>
  );
};

export default AppointmentForm;
