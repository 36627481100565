import React from 'react';
import styles from './HomePage.module.css';
import HeroSection from '../components/organism/HeroSection/HeroSection';
import HowToMarry from '../components/organism/HowToMarry/HowToMarry';
import ContentWrapper from '../components/molecules/ContentWrapper/ContentWrapper';
import Pricing from '../components/organism/Pricing/Pricing';
import TestimonialsCarousel from '../components/organism/TestimonialsGrid/TestimonialsGrid';
const testimonials = [
  {
    photo: 'https://masterpiecer-images.s3.yandex.net/aed2d08783d711eebab47a2f0d1382ba:upscaled',
    name: 'John & Jane',
    date: 'October 15, 2023',
    review: 'Our wedding was magical thanks to this amazing service!',
  },
  {
    photo: 'https://masterpiecer-images.s3.yandex.net/aed2d08783d711eebab47a2f0d1382ba:upscaled',
    name: 'Alex & Maria',
    date: 'November 5, 2023',
    review: 'We loved how simple and professional everything was!',
  },
  {
    photo: 'https://masterpiecer-images.s3.yandex.net/aed2d08783d711eebab47a2f0d1382ba:upscaled',
    name: 'Chris & Taylor',
    date: 'December 20, 2023',
    review: 'Highly recommend this service to anyone planning their wedding.',
  },
  {
    photo: 'https://masterpiecer-images.s3.yandex.net/aed2d08783d711eebab47a2f0d1382ba:upscaled',
    name: 'Jordan & Casey',
    date: 'January 3, 2024',
    review: 'Effortless, beautiful, and memorable! Thank you!',
  },
  {
    photo: 'https://masterpiecer-images.s3.yandex.net/aed2d08783d711eebab47a2f0d1382ba:upscaled',
    name: 'Alex & Maria',
    date: 'November 5, 2023',
    review: 'We loved how simple and professional everything was!',
  },
  {
    photo: 'https://masterpiecer-images.s3.yandex.net/aed2d08783d711eebab47a2f0d1382ba:upscaled',
    name: 'Chris & Taylor',
    date: 'December 20, 2023',
    review: 'Highly recommend this service to anyone planning their wedding.',
  },
  {
    photo: 'https://masterpiecer-images.s3.yandex.net/aed2d08783d711eebab47a2f0d1382ba:upscaled',
    name: 'Jordan & Casey',
    date: 'January 3, 2024',
    review: 'Effortless, beautiful, and memorable! Thank you!',
  },
  {
    photo: 'https://masterpiecer-images.s3.yandex.net/aed2d08783d711eebab47a2f0d1382ba:upscaled',
    name: 'Emily & Scott',
    date: 'February 14, 2024',
    review: 'Such a seamless process. Highly recommend!',
  },
  {
    photo: 'https://masterpiecer-images.s3.yandex.net/aed2d08783d711eebab47a2f0d1382ba:upscaled',
    name: 'Taylor & Morgan',
    date: 'March 8, 2024',
    review: 'The best decision we made for our wedding day!',
  },
  {
    photo: 'https://masterpiecer-images.s3.yandex.net/aed2d08783d711eebab47a2f0d1382ba:upscaled',
    name: 'Sam & Riley',
    date: 'April 22, 2024',
    review: 'Absolutely fantastic service. Thank you!',
  },
];

export function HomePage(){ 
return(
    <div className='homeContainer'>
      <HeroSection/>
      <ContentWrapper>
        <HowToMarry/>
        <Pricing/>
        <TestimonialsCarousel testimonials={testimonials} />
      </ContentWrapper>
    </div>
    );
}
