// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Title.module.css */
.Title_title__EWNDF {
    font-size: 2rem;
    color: #a45ca3; /* Основной фиолетовый цвет */
    font-weight: 800;
    text-align: center;
    margin-bottom: 1rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/atoms/TextComponents/Title/Title.module.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,eAAe;IACf,cAAc,EAAE,6BAA6B;IAC7C,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;EACrB","sourcesContent":["/* Title.module.css */\n.title {\n    font-size: 2rem;\n    color: #a45ca3; /* Основной фиолетовый цвет */\n    font-weight: 800;\n    text-align: center;\n    margin-bottom: 1rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Title_title__EWNDF`
};
export default ___CSS_LOADER_EXPORT___;
