// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Общий стиль для оболочки */
.ContentWrapper_wrapper__Kglrd {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: -50px; /* Смещаем вверх, чтобы перекрыть HeroSection */
  }
  
  /* Контент внутри оболочки */
  .ContentWrapper_content__-bI-r {
    width: 90%;
    max-width: 1200px; 
    background-color: white; /* Белый фон для контента */
    border-radius: 20px; /* Закруглённые углы */
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Легкая тень для выделения */
    z-index: 2; /* Поднимаем над HeroSection */
    margin-bottom: 2rem;
  }
  
  /* Адаптивность для планшетов */
  @media (max-width: 1024px) {
    .ContentWrapper_content__-bI-r {
      width: 90%; /* Ближе к полной ширине на планшетах */
    }
  }
  
  /* Адаптивность для смартфонов */
  @media (max-width: 768px) {
    .ContentWrapper_content__-bI-r {
      width: 100%; /* Полная ширина на смартфонах */
      border-radius: 0; /* Убираем закругление углов для мобильных устройств */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/molecules/ContentWrapper/ContentWrapper.module.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;IACI,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,WAAW;IACX,iBAAiB,EAAE,+CAA+C;EACpE;;EAEA,4BAA4B;EAC5B;IACE,UAAU;IACV,iBAAiB;IACjB,uBAAuB,EAAE,2BAA2B;IACpD,mBAAmB,EAAE,sBAAsB;IAC3C,gBAAgB;IAChB,yCAAyC,EAAE,8BAA8B;IACzE,UAAU,EAAE,8BAA8B;IAC1C,mBAAmB;EACrB;;EAEA,+BAA+B;EAC/B;IACE;MACE,UAAU,EAAE,uCAAuC;IACrD;EACF;;EAEA,gCAAgC;EAChC;IACE;MACE,WAAW,EAAE,gCAAgC;MAC7C,gBAAgB,EAAE,sDAAsD;IAC1E;EACF","sourcesContent":["/* Общий стиль для оболочки */\n.wrapper {\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n    width: 100%;\n    margin-top: -50px; /* Смещаем вверх, чтобы перекрыть HeroSection */\n  }\n  \n  /* Контент внутри оболочки */\n  .content {\n    width: 90%;\n    max-width: 1200px; \n    background-color: white; /* Белый фон для контента */\n    border-radius: 20px; /* Закруглённые углы */\n    overflow: hidden;\n    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Легкая тень для выделения */\n    z-index: 2; /* Поднимаем над HeroSection */\n    margin-bottom: 2rem;\n  }\n  \n  /* Адаптивность для планшетов */\n  @media (max-width: 1024px) {\n    .content {\n      width: 90%; /* Ближе к полной ширине на планшетах */\n    }\n  }\n  \n  /* Адаптивность для смартфонов */\n  @media (max-width: 768px) {\n    .content {\n      width: 100%; /* Полная ширина на смартфонах */\n      border-radius: 0; /* Убираем закругление углов для мобильных устройств */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ContentWrapper_wrapper__Kglrd`,
	"content": `ContentWrapper_content__-bI-r`
};
export default ___CSS_LOADER_EXPORT___;
