import React from "react";
import ContentWrapper from "../components/molecules/ContentWrapper/ContentWrapper";
import HeaderWithBackground from "../components/molecules/HeaderWithBackground/HeaderWithBackground";
import InfoPricing from "../components/molecules/InfoPricing/InfoPricing";
import pricingBack from "../assets/images/pricing_back.jpg";

export function PricingPage() {
    return (
        <div className="pricingContainer">
            <HeaderWithBackground 
                backgroundImage={pricingBack} 
                title="Choose Your Package" 
            />
            <ContentWrapper>
                <InfoPricing />
            </ContentWrapper>
        </div>
    );
}
