// CalendarScreen.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import { setDate, setTime, setCurrentStep } from '../../../../redux/slices/bookingSlice';
import { RootState } from '../../../../redux/store';
import SubmitButton from '../../../atoms/SubmitButton/SubmitButton';
import styles from './CalendarScreen.module.css';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const CalendarScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { date, time } = useSelector((state: RootState) => state.booking);

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date(date || Date.now()));
  const [availableTimes, setAvailableTimes] = useState<string[]>([]);
  const [selectedTime, setSelectedTime] = useState<string>(time || '');

  useEffect(() => {
    if (selectedDate) {
      const dayOfWeek = selectedDate.getDay();
      let times: string[] = [];

      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        const endHour = dayOfWeek === 5 ? 13 : 16;
        for (let hour = 10; hour <= endHour; hour++) {
          times.push(`${hour}:00`);
          times.push(`${hour}:30`);
        }
      }
      setAvailableTimes(times);
    }
  }, [selectedDate]);

  const handleDateChange = (value: Value) => {
    const date = Array.isArray(value) ? value[0] : value;
    if (date) {
      setSelectedDate(date);
      dispatch(setDate(date.toISOString().split('T')[0]));
      setSelectedTime('');
    }
  };

  const handleTimeSelect = (time: string) => {
    setSelectedTime(time);
    dispatch(setTime(time));
  };

  const handleNext = () => {
    if (selectedDate && selectedTime) {
      dispatch(setCurrentStep(2));
    }
  };

  return (
    <div className={styles.calendarContainer}>
      <div className={styles.calendarAndSlots}>
        <Calendar 
          value={selectedDate} 
          onChange={handleDateChange} 
          minDate={new Date()} 
        />
        <div className={styles.timeSlotsContainer}>
          {availableTimes.length > 0 ? (
            <div className={styles.timeSlots}>
              {availableTimes.map((time) => (
                <button
                  key={time}
                  className={`${styles.timeSlot} ${selectedTime === time ? styles.selected : ''}`}
                  onClick={() => handleTimeSelect(time)}
                >
                  {time}
                </button>
              ))}
            </div>
          ) : (
            <p>No available times for the selected date.</p>
          )}
        </div>
      </div>
      <div className={styles.buttons}>
        <SubmitButton label="Next" onClick={handleNext} disabled={!selectedTime} />
      </div>
    </div>
  );
  
};

export default CalendarScreen;
