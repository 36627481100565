import React from 'react';
import styles from './Footnote.module.css';

interface FootnoteProps {
  text: string;
}

const Footnote: React.FC<FootnoteProps> = ({ text }) => {
  return (
    <p className={styles.footnote}>
      * {text}
    </p>
  );
};

export default Footnote;
