import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HomePage } from '../pages/HomePage';
import { AboutUsPage } from '../pages/AboutUsPage';
import { LicensePage } from '../pages/LicensePage';
import { GetMarriedPage } from '../pages/GetMarriedPage';
import { PricingPage } from '../pages/PricingPage';
import NavBar from '../components/organism/Navbar/NavBar';
import Layout from '../components/Layout/Layout';

export function AppRouter() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/license" element={<LicensePage />} />
          <Route path="/married" element={<GetMarriedPage />} />

        </Routes>
      </Layout>
    </Router>
  );
}
