import React from 'react';
import styles from './SubmitButton.module.css';

interface SubmitButtonProps {
  label: string; 
  onClick?: () => void; 
  disabled?: boolean; 
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ label, disabled = false,onClick }) => {
  return (
    <button 
      className={styles.submitButton} 
      onClick={onClick} 
      type='submit'
      disabled={disabled}>
      {label}
    </button>
  );
};

export default SubmitButton;
