// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Subtitle.module.css */
.Subtitle_subtitle__JEi2Z {
    font-size: 1.25rem;
    color: #92488f; /* Более темный фиолетовый для подзаголовка */
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/atoms/TextComponents/Subtitle/Subtitle.module.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,kBAAkB;IAClB,cAAc,EAAE,6CAA6C;IAC7D,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;EACpB","sourcesContent":["/* Subtitle.module.css */\n.subtitle {\n    font-size: 1.25rem;\n    color: #92488f; /* Более темный фиолетовый для подзаголовка */\n    font-weight: 600;\n    margin-bottom: 1rem;\n    text-align: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": `Subtitle_subtitle__JEi2Z`
};
export default ___CSS_LOADER_EXPORT___;
