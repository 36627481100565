import React from 'react';
import styles from './ImagePreview.module.css';

interface ImagePreviewProps {
  src: string; // URL of the image to display
  alt?: string; // Alternative text for the image
  width?: string; // Optional width for the image
  height?: string; // Optional height for the image
  className?: string; // Optional additional class names
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ src, alt = 'image preview', width = '100%', height = 'auto', className }) => {
  return (
    <div className={`${styles.imageWrapper} ${className}`}>
      <img src={src} alt={alt} className={styles.image} style={{ width, height }} />
    </div>
  );
};

export default ImagePreview;
