import React from 'react';
import styles from './DateInput.module.css';

interface DateInputProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  className?: string;
}

const DateInput: React.FC<DateInputProps> = ({
  name,
  value,
  onChange,
  disabled = false,
  className,
}) => {
  return (
    <input
      type="date"
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={`${styles.input} ${className}`}
    />
  );
};

export default DateInput;
