// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Divider.module.css */

.HorizontalDivider_divider__VIfI4 {
    height: 2px;
    background-color: #d8a7d4; /* Светлый оттенок в стиле проекта */
    border: none;
    margin: 0.8rem 0;
    transition: background-color 0.3s ease, width 0.3s ease;
  }
  
  @media (max-width: 768px) {
    .HorizontalDivider_divider__VIfI4 {
      margin: 0.5rem 0;
    }
  }
  
  @media (max-width: 450px) {
    .HorizontalDivider_divider__VIfI4 {
      margin: 0.25rem 0;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/atoms/Divider/HorizontalDivider/HorizontalDivider.module.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,WAAW;IACX,yBAAyB,EAAE,oCAAoC;IAC/D,YAAY;IACZ,gBAAgB;IAChB,uDAAuD;EACzD;;EAEA;IACE;MACE,gBAAgB;IAClB;EACF;;EAEA;IACE;MACE,iBAAiB;IACnB;EACF","sourcesContent":["/* Divider.module.css */\n\n.divider {\n    height: 2px;\n    background-color: #d8a7d4; /* Светлый оттенок в стиле проекта */\n    border: none;\n    margin: 0.8rem 0;\n    transition: background-color 0.3s ease, width 0.3s ease;\n  }\n  \n  @media (max-width: 768px) {\n    .divider {\n      margin: 0.5rem 0;\n    }\n  }\n  \n  @media (max-width: 450px) {\n    .divider {\n      margin: 0.25rem 0;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `HorizontalDivider_divider__VIfI4`
};
export default ___CSS_LOADER_EXPORT___;
