// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SpecialPriceCard_title__9Aw\\+m {
  color: #990499;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-align: center;
}

.SpecialPriceCard_headerContainer__F886L {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.SpecialPriceCard_price__LcTQw {
  color: #333;
  font-size: 1rem;
  font-weight: 700;
  margin-top:0.5rem;
  text-align: center;
}


`, "",{"version":3,"sources":["webpack://./src/components/molecules/SpecialPriceCard/SpecialPriceCard.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".title {\n  color: #990499;\n  font-weight: bold;\n  font-size: 1.2rem;\n  margin-bottom: 10px;\n  text-align: center;\n}\n\n.headerContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n}\n\n.price {\n  color: #333;\n  font-size: 1rem;\n  font-weight: 700;\n  margin-top:0.5rem;\n  text-align: center;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `SpecialPriceCard_title__9Aw+m`,
	"headerContainer": `SpecialPriceCard_headerContainer__F886L`,
	"price": `SpecialPriceCard_price__LcTQw`
};
export default ___CSS_LOADER_EXPORT___;
