import React from 'react';
import styles from './HorizontalDivider.module.css';

interface HorizontalDividerProps {
  width?: string; 
  className?: string;
}

const HorizontalDivider: React.FC<HorizontalDividerProps> = ({ width = "100%", className }) => {
  return <hr className={`${styles.divider} ${className}`} style={{ width }} />;
};

export default HorizontalDivider;
